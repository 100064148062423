import { ContactReason } from "jrmc/vue/prelogin/enums/contact";

export class ContactForm {
  email: string;
  surname: string;
  name: string;
  phoneNumber: string;
  category: ContactReason;
  content: string;
  consent: boolean;
  constructor() {
    this.email = "";
    this.surname = "";
    this.name = "";
    this.phoneNumber = "";
    this.category = ContactReason.QUESTION;
    this.content = "";
    this.consent = false;
  }
}
