<script setup lang="ts"></script>
<template>
  <div class="loader">
    <div class="spinner"></div>
    <p>Chargement...</p>
  </div>
</template>
<style scoped>
.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader p {
  color: var(--color-dark-blue-secondary);
  font-weight: 600;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: var(--color-dark-blue-primary);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 0.8rem;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
