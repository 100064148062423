import { HttpClient } from "jrmc/vue/shared/api/http-client";
import { ConnexionForm } from "jrmc/vue/prelogin/models/ConnexionForm";
import { ConnexionResponse } from "jrmc/vue/prelogin/types/connexion-form";

export default new class LoginApi extends HttpClient{
    public constructor(){
        super("/api/v1/login")
    }
    async login(connexionForm : ConnexionForm){
        return this.instance.post<ConnexionResponse>('',connexionForm);
    }
}
