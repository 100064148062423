<script setup lang='ts'>
import {setMetaTags} from "jrmc/vue/prelogin/seo/seo.server";
import {useRoute} from "vue-router";
import Hero from "jrmc/vue/prelogin/components/Hero.vue";

const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
</script>
<template>
  <Hero :title="'Mentions légales'" />
  <div class="container page">
    <div class="block">
      <h2>Le site</h2>
      <p>
        Le site JeResilieMonContrat.com propose un service de résiliation de contrats d'assurance par lettre recommandée
        électronique ainsi qu'un service d'envoi de courriers papier.
      </p>
      <p>Ces services sont à vocation des professionnels de l’assurance (intermédiaires, assureurs, mutuelles etc
        …).</p>
      <p>L'accès au site, sa consultation et son utilisation sont subordonnés à l'acceptation sans réserve des
        Conditions
        Générales d'Utilisation et de Vente de JeResilieMonContrat.com. Nous nous réservons le droit d’en refuser
        l’accès,
        partiellement ou totalement, à tout utilisateur qui ne les respecterait pas.</p>
      <p>Nous rappelons par ailleurs à nos utilisateurs que les identifiants et mots de passe, indispensables pour
        accéder
        à votre compte JRMC ainsi qu’à ses informations diverses, sont strictement personnels et confidentiels.</p>
      <p>Par conséquent, la société CLIC’ISSIMO ne peut être tenue responsable de l’accès au compte JRMC par un tiers
        non-autorisé à l’aide desdits identifiants et mots de passe et des conséquences de cet accès frauduleux.</p>
    </div>
    <div class="block">
      <h2>Editeur du site</h2>
      <p>Le site est édité par la société <b>CLIC’ISSIMO</b>, Société par Actions Simplifiée au capital de 90 000€.</p>
      <p>La société CLIC’ISSIMO est immatriculée à Grenoble sous le numéro RCS : <b>810 506 055</b></p>
      <p>Le siège social est situé au <b>7 Chemin de la Triere 38240 MEYLAN</b>.</p>
      <p>Numéro de TVA intracommunautaire : <b>FR68810506055</b></p>
      <p>JeRésilieMonContrat.com est un service commercialisé par la société CLIC'ISSIMO.. </p>
      <p>Pour toute question relative à la société, nous vous invitons à nous contacter à l'adresse suivante : <a
        href="mailto:contact@jeresiliemoncontrat.com">contact@jeresiliemoncontrat.com</a></p>
    </div>
    <div class="block">
      <h2>Directeur de la publication</h2>
      <p>Madame Pauline GIBELIN</p>
    </div>
    <div class="block">
      <h2>Hébergement</h2>
      <p>Ce site internet est hébergé par la société OVH :</p>
      <address>
        OVH<br>
        2 rue Kellermann<br>
        59100 ROUBAIX<br>
        France<br>
        Tél : 08 203 203 63<br>
      </address>

      Pour contacter notre hébergeur, vous pouvez aussi vous rendre à l’adresse http://www.ovh.com/fr/support

    </div>
    <div class="block">
      <h2>Disponibilité du site web</h2>
      <p>
        Le site est en principe disponible pour les internautes 24h/24 et 7j/7, sauf interruption, programmée ou non,
        par exemple pour des besoins de maintenance ou en cas de force majeure à l’instar d’une panne des moyens de
        transmission et de communication.
      </p>
      <p>
        La société CLIC’ISSIMO se réserve le droit, à tout moment, d’interrompre temporairement ou de façon permanente
        tout ou partie du site pour effectuer des opérations de maintenance, d’améliorations et/ou des modifications du
        site.
      </p>
    </div>
    <div class="block">
      <h2>Propriété intellectuelle</h2>
      <p>
        Le site et l’intégralité des éléments qui le composent, notamment les marques, logos, signes, graphismes,
        photographies et tout autre contenu, font l’objet d’une protection par les législations françaises et
        internationales relatives à la propriété intellectuelle.
      </p>
      <p>
        Toute reproduction ou représentation, intégrale ou partielle, publication, transmission, copie des différents
        contenus du site, ou plus généralement toute exploitation non autorisée du site et/ou de ses éléments est
        strictement interdite sans l’autorisation préalable et écrite de la société CLIC’ISSIMO et est susceptible
        d’engager la responsabilité de son ou ses auteurs au titre notamment d’actes de contrefaçons.
      </p>
    </div>
    <div class="block">
      <h2>Responsabilité de l'éditeur</h2>
      <p>
        Les informations diffusées sur le site et/ou accessibles par ce site proviennent de sources qui sont réputées
        fiables et notre équipe met en oeuvre tous ses efforts afin d’afficher des informations exactes et à jour.
      </p>
      <p>
        Toutefois, et notamment car ces informations sont susceptibles de contenir des erreurs et/ou omissions dont nous
        ne pouvons être tenus pour responsables, notamment des inexactitudes techniques et des erreurs typographiques,
        nous nous réservons le droit de les corriger, dès que ces erreurs et/ou omissions sont portées à notre
        connaissance.
      </p>
      <p>
        Les informations sur le site sont en conséquence susceptibles d’être modifiées à tout moment, sans préavis.
      </p>
      <p>
        Ainsi, l'utilisateur assume seul l'entière responsabilité de l'utilisation des informations et contenus du
        présent site ainsi que du téléchargement de tous matériels et/ou documents lors de l’utilisation.<br/>
        La société CLIC’ISSIMO ne pourra en aucun cas être tenue responsable de tout dommage de quelque nature qu’il
        soit résultant de l’interprétation ou de l’utilisation des informations et contenus disponibles sur ce site.
        L’utilisateur est par ailleurs seul responsable de tout dommage subi par son équipement informatique et/ou de
        toutes pertes de données consécutives y afférent.
      </p>
      <p>
        Aussi, le site peut contenir des liens vers d’autres sites que nous n’exploitons pas et nous ne pouvons donc pas
        être tenus responsables de leur mise à disposition. De la même manière, en l’absence de vérification et de
        contrôle par notre équipe, nous ne pouvons pas être tenus responsables du contenu, publicités, produits et
        services émanant de ces sites externes.
      </p>
      <p>
        Plus généralement, la société CLIC’ISSIMO ne pourra en aucun cas être tenue responsable en cas de dommages
        directs et/ou indirects résultant de l’utilisation du site.
      </p>
    </div>
    <div class="block">
      <h2>Contact et service clients
      </h2>
      <p>Pour toute demande, vous pouvez nous écrire via le formulaire de contact accessible depuis la page d’accueil du
        site web ou bien directement via le menu Aide accessible depuis votre compte JRMC.
      </p>
      <p>
        Nos équipes sont disponibles du lundi au vendredi, de 9h à 12h et de 14h à 18h (hors jours fériés – horaires
        France métropolitaine).
      </p>
    </div>
  </div>
</template>
<style scoped>
.title {
  margin-top: 2rem;
  text-align: center;
}

.title h1 {
  font-size: clamp(1.2rem, 2.2vw, 2.5rem);
}

.block {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.block a {
  color: var(--color-orange)
}

.block ol,
.block ul {
  padding: 0 2rem;
}

.block li {
  list-style: auto;
  color: var(--color-dark-blue-secondary);
}

.block ul li {
  list-style-type: circle
}
</style>
