import {
  createRouter as _createRouter,
  createMemoryHistory,
  createWebHistory,
} from 'vue-router'
import routes from "jrmc/vue/prelogin/router/routes";
import { siteName, titleSeparator } from "jrmc/vue/prelogin/seo/seo.server";
// Auto generates routes from vue files under ./pages
// https://vitejs.dev/guide/features.html#glob-import

export function createRouter() {
  const router =  _createRouter({
    // use appropriate history implementation for server/client
    // import.meta.env.SSR is injected by Vite.
    history: import.meta.env.SSR
            ? createMemoryHistory('/')
            : createWebHistory('/'),
    routes,
    scrollBehavior(to,from,savedPosition){
      return {top: 0}
    }
  })

  router.beforeEach((to, from, next) => {
    if (!import.meta.env.SSR) {
      // @ts-ignore
      document.title = to.meta.pageTitle + ' ' + titleSeparator + ' ' + siteName;
    }

    next();
  });

  return router
}
