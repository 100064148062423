<script setup lang='ts'>
import ResetPasswordSubmissionSection from "jrmc/vue/prelogin/components/ResetPasswordSubmission/ResetPasswordSubmissionSection.vue";
import { setMetaTags } from "jrmc/vue/prelogin/seo/seo.server";
import {useRoute} from "vue-router";
const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
</script>
<template>
    <ResetPasswordSubmissionSection/>
</template>
<style scoped>
</style>
