<script setup lang="ts">
import { useField } from "vee-validate";
import { VNodeRef, ref } from "vue";
import useAssets from "jrmc/vue/prelogin/composable/useAssets";
const props = defineProps<{
  name: string;
  label?: string;
  validation?: boolean;
}>();
defineOptions({
  inheritAttrs: false,
});

const innerRef = ref<VNodeRef | null>(null);

const showPassWord = () => {
  if (innerRef.value?.type === "password") {
    innerRef.value.type = "text";
  } else {
    innerRef.value.type = "password";
  }
};
const { value, errorMessage } = useField(() => props.name);
</script>
<template>
  <div class="input">
    <label :for="name" v-if="label">{{ label }}</label>
    <div class="input-and-icon">
      <input
        :class="[errorMessage ? 'error-field' : '']"
        v-model="value"
        v-bind="$attrs"
        ref="innerRef"
        type="password"
        :name="name"
      />
      <img
        :src="useAssets('images/eye_icon.svg')"
        alt="eye icon"
        width="29"
        height="13"
        @click="showPassWord"
      />
    </div>

    <p class="error" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>
<style scoped>
.input input {
  padding: 0.8rem 1rem;
  background-color: var(--color-white);
  border-radius: 15px;
  border: 2px var(--color-dark-blue-primary) solid;
  width: 100%;
}
.input-and-icon {
  position: relative;
}
.input img {
  width: 1rem;
  position: absolute;
  bottom: 50%;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  cursor: pointer;
}
.input label {
  display: block;
  color: var(--color-dark-blue-secondary);
  font-weight: 600;
  font-size: 0.8rem;
  padding-left: 0.5rem;
}
</style>
