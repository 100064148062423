<script setup lang='ts'>
import QualityMapCard from "jrmc/vue/prelogin/components/QualityMap/QualityMapCard.vue";

const pictos = [
  {
    title: "Simple",
    img: "Picto_Timeline_Smiley_Simple.svg",
  },
  {
    title: "Expertise",
    img: "Picto_Timeline_2015_Expertise.svg",
  },
  {
    title: "Légal",
    img: "Picto_Timeline_Balance_Legal.svg",
  },
  {
    title: "Sans abonnement",
    img: "Picto_Timeline_Cadenas_Abonnement.svg",
  },
  {
    title: "Sécurisé",
    img: "Picto_Timeline_Cle_Securise.svg",
  },
  {
    title: "+900 destinataires",
    img: "Picto_Timeline_Avion_Destinataires.svg",
  },
  {
    title: "100% Français",
    img: "Picto_Timeline_Drapeau_Francais.svg",
  },
];
</script>
<template>
    <div class="quality-map-container">
        <QualityMapCard
          v-for="picto in pictos"
          :title="picto.title"
          :img="picto.img"
          :key="picto.title"
        />
      </div>
</template>
<style scoped>
.quality-map-container {
  position: relative;
  margin-top: 4rem;
  display: flex;
  gap: 1rem;
  justify-content: space-around;
}

.quality-map-container::after {
  position: absolute;
  top: 28%;
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--color-orange);
}

@media screen and (max-width: 1024px) {
  .quality-map-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border: 2px var(--color-orange) solid;
    padding: 2rem 0.5rem 0;
    border-radius: 20px;
    height: 21rem;
    margin-bottom: 4rem;
  }

  .quality-map-container > :last-child {
    grid-column: 1 / -1;
  }

  .quality-map-container::after {
    display: none;
  }
}
</style>
