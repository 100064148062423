<script setup lang="ts">
import CourierInfoCard from "jrmc/vue/prelogin/components/Tarifs/CourierInfoCard.vue";
const courierInfoCards = [
  {
    title: `Avis de dépôt`,
    text: `Recevez un avis de dépôt immédiat pour chaque courrier envoyé.
          Cela garantit la traçabilité de vos envois et vous fournit une preuve de dépôt, essentielle pour vos documents importants.`,
    icon: `avis_de_depot.svg`,
  },
  {
    title: `Suivi Précis en Temps Réel`,
    text: `Profitez d'un suivi précis et en temps réel pour tous vos envois. Restez informé à chaque étape du parcours de votre courrier,
          de l'envoi à la réception pour une visibilité complète et une tranquillité d'esprit.`,
    icon: `Double_Check_Tous_Envois.svg`,
  },
  {
    title: `Disponibilité 24h/24 et 7j/7`,
    text: `Notre solution est accessible à toute heure, tous les jours, vous permettant d'envoyer ou de gérer vos courriers quand cela vous convient le mieux.
            Cette flexibilité est idéale pour répondre aux besoins urgents ou aux horaires atypiques.`,
    icon: `24_7.svg`,
  },
  {
    title: `Équipe support disponible`,
    text: `Notre équipe est disponible pour vous accompagner du lundi au vendredi, de 9h à 12h et de 14h à 18h. Contactez-nous depuis le menu Aide ou depuis notre formulaire de contact.`,
    icon: `Casque_Equipe_Support.svg`,
  },
];
</script>

<template>
  <section>
    <div class="container">
      <h2>Pour tous vos envois de courriers électroniques et papier</h2>
      <div class="courier-info-section">
        <CourierInfoCard
          v-for="infoCard in courierInfoCards"
          v-bind="infoCard"
        />
      </div>
    </div>
  </section>
</template>

<style scoped>
.courier-info-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 3rem;
}
</style>
