<script setup lang="ts">
import { SendMode } from "jrmc/vue/shared/models/paperletter/SendMode";
import {computed, onMounted, reactive, ref, useSSRContext, watch} from "vue";
import {LetterPriceApi} from "jrmc/vue/shared/api/paperletter/price";
import { Routes } from "jrmc/vue/prelogin/enums/routes";
import Loader from "jrmc/vue/prelogin/components/Loader.vue";
import {SendModeApi} from "jrmc/vue/shared/api/paperletter/send-mode";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import { faPaperclip } from '@fortawesome/free-solid-svg-icons'

const isLoading = ref(true);
const letterTypes = ref<Array<SendMode>>([]);
const price = ref<number>();
const error = ref<boolean>(false);
const letter = reactive({
  id: 0,
  quantity: 1,
  isStappled: false,
  stapplingCount: 1,
});

const numbersOfStapplingOption = computed(() => {
  const stapplingOptions = [];
  for (let i = 1; i <= letter.quantity / 2; i++) {
    const option = {
      value: i,
      name: i === 1 ? `${i} agrafe` : `${i} agrafes`,
    };
    stapplingOptions.push(option);
  }
  return stapplingOptions;
});

const handleClickIncreaseQty = () => {
  if(!error.value){
    letter.quantity += 1;
  }
};
const handleClickDecreaseQty = () => {
  if (letter.quantity > 1) {
    letter.quantity -= 1;
  }
};
const handleChangeStapplingCount = (event: Event) => {
  const element = event.target as HTMLSelectElement;
  const value = element.value;
  letter.stapplingCount = parseInt(value);
};
const handleChangeLetterType = (letterType: number) => {
  letter.id = letterType;
};

const updateLetterPrice = async () => {
  try{
    const response = await letterPriceApi.getLetterPrice(letter);
    price.value = response.price;
    error.value = false;
  }catch(e){
    error.value = true;
  }
};

let ctx = undefined;
if (import.meta.env.SSR) {
  ctx = useSSRContext();
}
const sendModeApi = new SendModeApi(ctx);
const letterPriceApi = new LetterPriceApi(ctx);
const sendModes = await sendModeApi.getSendModes();
letterTypes.value = sendModes;
letter.id = letterTypes.value[0].id;
await updateLetterPrice();
isLoading.value = false;

watch(letter, async () => {
  await updateLetterPrice();
});

</script>

<template>
  <Loader v-if="isLoading" />
  <div class="price-simulator-container" v-else>
    <p>
      Tous nos envois papiers sont en couleurs et en impression Recto/Verso.
    </p>
    <div class="simulator">
      <!-- quantity -->
      <div class="qty">
        <p>Nombre de feuilles</p>
        <div class="qty-selector">
          <button @click="handleClickDecreaseQty" data-test="btn-minus">
            -
          </button>
          <span>{{ letter.quantity }}</span>
          <button @click="handleClickIncreaseQty" data-test="btn-plus">
            +
          </button>
        </div>
        <p v-if="error" class="error">Nombre de feuilles max. atteint</p>
      </div>
      <!-- type of letter and stappling  -->
      <div class="simultor-options">
        <div class="letter-type">
          <p>Type d'envoi</p>
          <div class="sendmode">
            <span
              :class="[letter.id === letterType.id ? 'isSelected' : '']"
              v-for="letterType in letterTypes"
              :key="letterType.id"
              @click="handleChangeLetterType(letterType.id)"
              >{{ letterType.name }}</span
            >
          </div>
        </div>
        <div class="stappling">
          <div class="stappling-input">
            <label class="tooltip" for="stappled">
              <span v-if="letter.quantity < 2" class="tooltiptext">L'agrafage n'est possible qu'à partir de deux feuilles</span>
              <FontAwesomeIcon :icon="faPaperclip" />
              Agrafage
            </label>
            <input
              type="checkbox"
              name="stappled"
              id="stappled"
              v-model="letter.isStappled"
              :disabled="letter.quantity < 2"
            />
          </div>
          <div
            class="stappling-count"
            v-if="letter.isStappled && letter.quantity >= 2"
          >
            <label for="stappling-count">Nombre d'agafes</label>
            <select name="stappling-count" @change="handleChangeStapplingCount">
              <option
                v-for="option in numbersOfStapplingOption"
                :value="option.value"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <!-- price and cta -->
      <div class="price-and-ctas">
        <div class="price">
          <p>Tarif en TTC</p>
          <span>{{ price }} €</span>
        </div>
        <RouterLink :to="{ name: Routes.INSCRIPTION }" class="btn btn-blue"
          >Envoyer un courrier</RouterLink
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.price-simulator-container {
  background: var(--color-light-blue-primary);
  padding: 2rem;
  margin-top: 2rem;
  border-radius: var(--border-radius);
  border: 3px solid #c9e7e9;
}

.price-simulator-container > p {
  font-weight: 600;
  margin-bottom: 1rem;
}

.simulator {
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
}

.qty {
  background-color: var(--color-white);
  border: 2px var(--color-dark-blue-secondary) solid;
  border-radius: var(--border-radius);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-weight: 600;
  height: fit-content;
  white-space: nowrap;
}

.qty-selector {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.qty-selector button {
  background-color: transparent;
  color: var(--color-orange);
  font-weight: 600;
  font-size: 1.5rem;
  cursor: pointer;
}

.qty-selector span {
  font-size: 1.5rem;
}

.letter-type {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
}

.letter-type > p {
  font-weight: 600;
  white-space: nowrap;
}

.letter-type span {
  background-color: var(--color-white);
  color: var(--color-dark-blue-secondary);
  padding: 0.25rem 1rem;
  border: 2px var(--color-dark-blue-secondary) solid;
  border-radius: 25px;
  cursor: pointer;
  white-space: nowrap;
}

.letter-type .isSelected {
  background-color: var(--color-dark-blue-secondary);
  color: var(--color-white);
}

.sendmode {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.stappling {
  display: flex;
  gap: 1rem;
}

.stappling-count {
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 2px var(--color-dark-blue-secondary) solid;
  border-radius: var(--border-radius);
  padding: 1rem;
  background-color: var(--color-white);
}

.stappling-count select {
  background-color: transparent;
  color: var(--color-dark-blue-secondary);
  font-weight: 600;
  appearance: auto;
}

.price-and-ctas {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.price-and-ctas a {
  white-space: nowrap;
}

.price {
  text-align: right;
}

.price p {
  font-size: 0.8rem;
}

.price span {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--color-dark-blue-secondary);
}
/*tooltip*/
.tooltip {
  position: relative;
}
/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 1rem;
  border-radius: 6px;
  position: absolute;
  top: 1.5rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  width: 120px;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  transform: rotateZ(180deg);
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 1440px) {
  .simulator {
    display: grid;
    grid-template-columns: 25% 70%;
    grid-template-rows: auto auto;
  }

  .price-and-ctas {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
  }
}
@media screen and (max-width: 1024px) {
  .simulator {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 650px) {
  .stappling {
    flex-wrap: wrap;
  }

  .stappling-count {
    width: 100%;
  }
}
</style>
