<script setup lang="ts">
import Hero from 'jrmc/vue/prelogin/components/Hero.vue';
import PriceCardSection from "jrmc/vue/prelogin/components/Tarifs/PriceCardSection.vue"
import SimulatorSection from "jrmc/vue/prelogin/components/PriceSimulator/SimulatorSection.vue"
import CourierInfoSection from "jrmc/vue/prelogin/components/Tarifs/CourierInfoSection.vue"
import ElectronicSignatureSection from "jrmc/vue/prelogin/components/Tarifs/ElectronicSignatureSection.vue"
import FaqByPage from "jrmc/vue/prelogin/components/FaqByPage.vue"
import { FaqSpecificPage } from "jrmc/vue/prelogin/enums/faq";
import { setMetaTags } from "jrmc/vue/prelogin/seo/seo.server";
import {useRoute} from "vue-router";
import { useFaqData } from 'jrmc/vue/prelogin/composable/useFaqData';
const { faq, loading, fetchData } = useFaqData();
const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
await fetchData(FaqSpecificPage.TARIFS);

</script>
<template>
    <div class="page">
        <Hero :title="'Nos tarifs'" :img="'images/photo_header_tarif.webp'" :left="false"/>
        <PriceCardSection/>
        <SimulatorSection/>
        <CourierInfoSection/>
        <ElectronicSignatureSection/>
        <FaqByPage :faqs="faq" :loading="loading"/>
    </div>
</template>
