<script setup lang="ts">
import Input from "jrmc/vue/prelogin/components/Fields/Input.vue";
import { Routes } from "jrmc/vue/prelogin/enums/routes";
import { useForm } from "vee-validate";
import ResetPasswordApi from "jrmc/vue/prelogin/api/reset-password";
import { ref } from "vue";
import { useRoute } from "vue-router";

const isSubmitted = ref(false);
const { handleSubmit, isSubmitting } = useForm({
  validationSchema: {
    email: 'email|required'
  }
});
const handleSubmitResetPassword = handleSubmit(async (values) => {
  await ResetPasswordApi.sendPasswordResetEmail(values.email);
  isSubmitted.value = true;
});
</script>
<template>
  <div v-if="isSubmitted" class="container submitted-reset-password">
    <p>
      Un mail à l'adresse indiquée a été envoyé afin de vous indiquer la
      démarche à suivre.
    </p>
    <RouterLink :to="{ name: Routes.HOME }" class="btn btn-blue"
      >Retour</RouterLink
    >
  </div>
  <div v-else class="container reset-password-form">
    <h1>Réinitialisation du mot de passe</h1>
    <form @submit="handleSubmitResetPassword">
      <Input
        :name="'email'"
        :label="'Merci de fournir l’email avec lequel vous avez créé votre compte'"
        :placeholder="'Adresse email'"
      />
      <div class="cta-reset">
        <button
          :class="[isSubmitting ? 'loading' : '', 'btn-lg', 'btn-blue']"
          :disabled="isSubmitting"
        >
          Envoyer
        </button>
        <RouterLink :to="{ name: Routes.HOME }" class="underline"
          >Retour</RouterLink
        >
      </div>
    </form>
  </div>
</template>
<style scoped>
.reset-password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 50%;
}

.reset-password-form h1 {
  text-align: center;
  font-size: clamp(1rem, 2.2vw, 1.5rem);
}

.reset-password-form form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.cta-reset {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.cta-reset a {
  align-self: center;
}
.submitted-reset-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.submitted-reset-password p {
  font-size: 1.2rem;
  font-weight: 600;
}
@media screen and (max-width: 1024px) {
  .reset-password-form {
    max-width: 70%;
  }
  .reset-password-form h1 {
    font-size: clamp(1.3rem, 2.5vw, 1.5rem);
  }
}
</style>
