<script setup lang="ts">
import AdvantageComponent from "jrmc/vue/prelogin/components/Home/AdvantageComponent.vue";
const advantages = [
  {
    img: "smile-orange.svg",
    title: "Sans abonnement",
    text: `Notre solution est <strong>simple et sans engagement</strong>,
        vous ne payez que le prix de la lettre que vous envoyez !`,
  },
  {
    img: 'legal-orange.svg',
    title: 'Légal',
    text: `Notre solution est <strong>légale et sécurisée</strong>. En accord avec les
        recommandations de la <strong>Fédération Française de l’Assurance</strong>,
        elle vous permet de faire vos envois en toute sérénité et de
        vous assurer de la bonne réception du courrier.`
},
{
    img: '2015.svg',
    title: 'Expertise',
    text: `Avec <strong>10 ans d’ancienneté et plus de 6 millions d’envois</strong>,
        JRMC est expert dans la génération et l’envoi de lettres de résiliation de contrats d’assurance.<br/> Entre vous et nous, c'est une relation qui dure !`
},
{
    img: 'paper-plane-orange.svg',
    title: '+900 destinataires',
    text: `Nous vous mettons à disposition notre annuaire composé de <strong>plus de 900 références</strong> et vous
        n'avez plus qu'à choisir le destinataire de votre lettre de résiliation parmi la liste proposée.`
}
];
</script>
<template>
  <section>
    <div class="container advantage-section-container">
      <h2>Résiliez en toute simplicité !</h2>
      <p>Avec JeResilieMonContrat, fini les prises de tête ! </p>
      <div class="advantage-container">
        <AdvantageComponent v-for="advantage in advantages" v-bind="advantage"/>
      </div>
    </div>
  </section>
</template>
<style scoped>
.advantage-container{
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 2rem 10rem;
}
.advantage-section-container > p {
  color: var(--color-orange);
  font-weight: 700;
}
@media screen and (max-width: 650px) {
    .advantage-container{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}
</style>
