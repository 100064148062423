<script setup lang="ts">
import useAssets from "jrmc/vue/prelogin/composable/useAssets";
import { Routes } from "jrmc/vue/prelogin/enums/routes";

defineProps<{
  title: string;
  paragraph: string;
  priceFreeTax: number;
  characteristics: String[];
  isLetterPaper: boolean;
}>();
const calculatePrice = (priceFreeTax: number) => {
  const priceWithVat = priceFreeTax * 1.2;
  return priceWithVat.toFixed(2);
};
</script>

<template>
  <article class="price-card">
    <div class="card">
      <div class="card-title">
        <h3>{{ title }}</h3>
        <p>{{ paragraph }}</p>
        <div class="prices" v-if="isLetterPaper">
          <strong>à partir de {{ priceFreeTax }}€ TTC </strong>
        </div>
        <div class="prices" v-else>
          <strong>{{ priceFreeTax }}€ HT </strong>
          <p>{{ calculatePrice(priceFreeTax) }}€ TTC</p>
        </div>
      </div>
      <div class="characteristic">
        <p v-for="characteristic in characteristics">
          <img :src="useAssets('images/checkmark.svg')" alt="checkmark" width="24" height="18"/>{{
            characteristic
          }}
        </p>
      </div>
    </div>
    <RouterLink class="btn btn-blue" :to="{ name: Routes.CONNEXION }"
      >Envoyer mon courrier</RouterLink
    >
  </article>
</template>

<style scoped>
.price-card {
  flex: 1 1 0;
  color: var(--color-dark-blue-secondary);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: min(2rem, 6vw);
  border: 2px var(--color-light-blue-primary) solid;
  border-radius: 15px;
  box-shadow: var(--boxshadow);
}
.price-card a {
  white-space: nowrap;
}
.card {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 3rem;
  height: 100%;
}
.card > p {
  font-size: 0.9rem;
}
.card-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
}
.card-title h3{
  font-size : clamp(1rem,2vw,1.1rem);
}
.card-title p {
  font-size: clamp(0.8rem, 1vw, 1rem);
}
.prices p {
  font-size: 0.8rem;
}
.characteristic {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.characteristic p {
  display: flex;
  gap: 1rem;
  align-items: center;
  font-size: clamp(0.8rem, 1vw, 1rem);
}
.characteristic img {
  width: 1.5rem;
  object-fit: contain;
}
@media screen and (max-width: 1024px) {
  .price-card {
    min-width: 30vw;
  }
  .card-title {
    gap: 0.5rem;
  }
}
@media screen and (max-width: 650px) {
  .price-card {
    min-width: 50vw;
  }
}
</style>
