<script setup lang="ts">
import useAssets from "jrmc/vue/prelogin/composable/useAssets";
import { Routes } from "jrmc/vue/prelogin/enums/routes";
const argumentsList = [
  "Rapidité",
  "Sans déplacement",
  "Traçabilité",
  "Flexibilité",
];
</script>

<template>
  <div class="electronic-signature-section">
    <div class="arguments">
      <h3>Signature électronique par le souscripteur du contrat</h3>
      <p>
        Faites signer à distance le mandat de résiliation.
      </p>
      <ul>
        <li v-for="argument in argumentsList">
          <img :src="useAssets('images/checkmark.svg')" alt="tarif" loading="lazy" width="16" height="12"/>
          {{ argument }}
        </li>
      </ul>
    </div>
    <div class="ctas-electronic">
      <div class="price">
        <strong>1.25€ HT</strong>
        <p>1.50€ TTC</p>
      </div>
      <RouterLink :to="Routes.INSCRIPTION" class="btn btn-blue"
        >Envoyer mon courrier</RouterLink
      >
    </div>
  </div>
</template>

<style scoped>
.electronic-signature-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  border: var(--light-blue-border);
  border-radius: var(--border-radius);
  padding: 2.5rem;
  margin-top: 2rem;
  box-shadow: var(--boxshadow);
}
.electronic-signature-section h3 {
  font-size: clamp(1.2rem, 2.5vw, 1.3rem);
}
.arguments ul {
  padding: 1.5rem 0 0 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}
.arguments li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-dark-blue-secondary);
}
.arguments img {
  width: 1rem;
  object-fit: contain;
}
.ctas-electronic {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.ctas-electronic strong {
  font-size: clamp(1.2rem, 2vw, 1.3rem);
}
.price {
  text-align: end;
}
.price p{
  font-size: 0.9rem;
}
@media screen and (max-width: 1024px) {
  .electronic-signature-section {
    gap: 1.5rem;
  }
  .ctas {
    align-items: baseline;
    width: 100%;
  }
  .ctas a {
    width: 100%;
  }
  .price {
    text-align: left;
    align-self: flex-end;
  }
}
@media screen and (max-width: 650px) {
  .arguments ul {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
  }
  .price {
    align-self: flex-start;
  }
}
</style>
