<script setup lang="ts">
import PriceCard from "jrmc/vue/prelogin/components/Tarifs/PriceCard.vue";
const priceCards = [
  {
    title: "Lettre Recommandée Électronique sans Accusé de Réception",
    paragraph: `Envoyez vos demandes de résiliation de contrats d’assurance par lettre recommandée électronique.`,
    priceFreeTax: 2.59,
    characteristics: [
      "Avis de dépôt",
      "Réception de votre courrier assurée",
      "+ 900 professionnels de l’assurance destinataires",
    ],
    isLetterPaper: false,
  },
  {
    title: "Lettre Recommandée Électronique avec Accusé de Réception",
    paragraph: `Envoyez vos demandes de résiliation de contrats d’assurance par lettre recommandée électronique.`,
    priceFreeTax: 3.29,
    characteristics: [
      "Avis de dépôt",
      "Réception de votre courrier assurée",
      "+ 900 professionnels de l’assurance destinataires",
      "Accusé de réception",
    ],
    isLetterPaper: false,
  },
  {
    title:
      "Lettre Recommandée Électronique avec Accusé de Réception vers les banques",
    paragraph: `Envoyez vos lettres de changement d’assurance emprunteur par lettre recommandée électronique.`,
    priceFreeTax: 4.74,
    characteristics: [
      "Avis de dépôt",
      "Réception de votre courrier assurée",
      "+ 120 établissements bancaires destinataires",
      "Accusé de réception",
    ],
    isLetterPaper: false,
  },
  {
    title: "Courriers papier",
    paragraph: `Envoyez tous types de courrier au destinataire de votre choix.`,
    priceFreeTax: 1.61,
    characteristics: [
      "Suivi simplifié",
      "Justificatifs d'envoi dématérialisés",
      "Avec ou sans AR",
      "Plusieurs types d'envois possibles"
    ],
    isLetterPaper: true,
  },
];
</script>

<template>
  <section>
    <div class="container">
      <h2>Des prix compétitifs pour vos lettres recommandées en ligne et courriers papier</h2>
      <div class="price-card-section">
        <PriceCard v-for="priceCard in priceCards" v-bind="priceCard" />
      </div>
    </div>
  </section>
</template>

<style scoped>
.price-card-section {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}
</style>
