<script setup lang="ts">
import useAssets from "jrmc/vue/prelogin/composable/useAssets";
defineProps<{
  title?: string;
  subtitle?: string;
}>();
const relativeUrls = [
  "Mutuelle_Just",
  "Kiassure",
  "Logo_Filiassur",
  "Sycra",
  "UTWIN",
];
</script>
<template>
  <div class="container">
    <h2>{{ title }}</h2>
    <p>{{ subtitle }}</p>
  </div>
  <div class="partners-wrapper">
    <div class="partners-container">
      <img
        v-for="url in relativeUrls"
        width="192"
        height="64"
        :src="useAssets(`images/partners/${url}.svg`)"
        :alt="url"
        loading="lazy"
      />
    </div>
  </div>
</template>
<style scoped>
.container p {
  color: var(--color-orange);
  font-weight: 700;
}

.partners-wrapper {
  margin-top: 1rem;
  overflow-x: hidden;
}

@keyframes partnersAnimation {
  100% {
    transform: translateX(30%);
  }
}

.partners-container {
  display: flex;
  align-items: center;
  width: 150%;
  justify-content: space-between;
  gap: 2rem;
  transform: translateX(-60%);
  animation: partnersAnimation 15s linear 2s infinite alternate forwards;
}

.partners-container:hover {
  animation-play-state: paused;
}

.partners-container img {
  width: 12rem;
  height: 4rem;
  object-fit: contain;
  filter: contrast(0);
  transition: var(--transition-all);
}

.partners-container img:hover {
  filter: none;
}

@media screen and (max-width: 1024px) {
  .partners-container img {
    width: 10rem;
  }
}

@media screen and (max-width: 650px) {
  .partners-container {
    width: 200%;
  }
  .partners-container img {
    width: 8rem;
  }
  p {
    margin-bottom: 1rem;
  }
}
</style>
