<script setup lang="ts">
import FormImage from "jrmc/vue/prelogin/components/FormImage.vue";
import ResetPasswordForm from "jrmc/vue/prelogin/components/ResetPassword/ResetPasswordForm.vue";

const imageFormData = {
  imageUrl: "woman_connexion.webp",
  left: true,
  title: "Nous sommes ravis de vous revoir !",
};
</script>

<template>
  <div class="reset-section">
    <div class="container reset-container">
      <FormImage v-bind="imageFormData" />
      <ResetPasswordForm />
    </div>
  </div>
</template>

<style scoped>
.reset-section {
  background-image: url("../../assets/images/waves_pattern.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: var(--color-light-blue-primary);
  background-size: 60%;
  padding: 2rem 0;
}
.reset-container {
  display: flex;
  align-items: center;
  gap: 3rem;
  min-height: calc(100vh - 250px);
}
@media screen and (max-width: 1024px) {
  .glassmorph-container {
    display: none;
  }
  .reset-container {
    justify-content: center;
  }
}
</style>
