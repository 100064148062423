<script setup lang="ts">
import useAssets from "jrmc/vue/prelogin/composable/useAssets";
import { Routes } from "jrmc/vue/prelogin/enums/routes";
import ReinsuranceComponent from "jrmc/vue/prelogin/components/ReinsuranceComponent.vue";
const elements = [
  {
    img: "Picto_Destinataire.svg",
    title: "+900",
    text: "destinataires",
  },
  {
    img: "paper-plane-orange.svg",
    title: "+7.5M",
    text: "d'envois",
  },
  {
    img: "Picto_Expertise.svg",
    title: "+10 ans",
    text: "d'expérience",
  },
];
</script>
<template>
  <header>
    <div class="container hero-container">
      <div class="left">
        <h2>
          L'art de rompre <br />
          en 1 clic.
        </h2>
        <p>
          La solution en ligne rapide et efficace pour optimiser vos démarches de résiliation de contrats d'assurance.
        </p>
        <RouterLink :to="{ name: Routes.INSCRIPTION }" class="btn btn-orange"
          >Résiliez dès maintenant</RouterLink
        >
        <div class="reinsurance-elements">
          <ReinsuranceComponent
            v-for="(element, index) in elements"
            v-bind="element"
            :isBordered="index === elements.length - 1"
          />
        </div>
      </div>
      <div class="right">
        <img
          width="748"
          height="614"
          :src="useAssets('images/Photo_Homme_Avion_Hero.webp')"
          alt="photo homme header"
          :srcset="`${useAssets(
            'images/Photo_Homme_Avion_Hero_scale,w_200.webp'
          )} 280w, ${useAssets(
            'images/Photo_Homme_Avion_Hero_scale,w_312.webp'
          )} 300w, ${useAssets(
            'images/Photo_Homme_Avion_Hero_scale,w_565.webp'
          )} 550w`"
          sizes="(max-width: 650px) 273px, 550px"
        />
      </div>
    </div>
  </header>
</template>
<style scoped>
header {
  background: linear-gradient(180deg, rgba(147, 207, 212, 0.6) 0%, #fff 91.84%);
}

.hero-container {
  padding-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
}

.left {
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: min(2rem, 4vw);
}

.left a {
  width: fit-content;
}

.left h2,
.left p {
  color: var(--color-dark-blue-secondary);
}
.left h2 {
  font-size: clamp(3rem, 4vw, 4.5rem);
  line-height: 1;
  text-shadow: 4px 4px 4px rgba(1, 0, 56, 0.1);
}
.left p {
  font-size: clamp(1rem, 1.5vw, 1.2rem);
  line-height: 1.2;
}

.right img {
  height: 100%;
  max-width: min(550px, 70vw);
  width: 100%;
}
.reinsurance-elements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  header {
    min-height: auto;
  }
  .hero-container {
    padding: 1rem 0;
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .left {
    padding: 0;
    width: 95%;
  }
  .left h2 {
    font-size: clamp(3rem, 9vw, 5rem);
  }

  .left p {
    font-size: clamp(1rem, 2vw, 1.5rem);
  }

  .left a {
    width: 100%;
  }
  @media screen and (max-width: 650px) {
    .left h2 {
      white-space: nowrap;
      font-size: clamp(2.3rem, 10vw, 4rem);
    }
    .reinsurance-elements {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .reinsurance-element:nth-last-child(1) {
      grid-column: 1 / span 2;
    }
  }
}
</style>
