<script setup lang="ts">
import { ref } from "vue";
import PlusIcon from "jrmc/vue/prelogin/components/Icons/PlusIcon.vue";
import { Faq } from "jrmc/vue/prelogin/types/faq";

defineProps<{
  question: Faq;
  inFaqPage: boolean
}>();

const isActive = ref(false);

const toggle = () => {
  isActive.value = !isActive.value;
};
</script>
<template>
  <div class="question-answer" :class="[inFaqPage ? 'question-answer-faq-page' : 'question-answer-no-faq-page']" @click="toggle">
    <div :class="[isActive ? 'isActive' : 'isInactive', 'question']">
      <h3>{{ question.question }}</h3>
      <PlusIcon />
    </div>
      <div :class="[isActive ? 'open' : '', 'answer']">
        <p v-html="question.response.replace(/\n/g, '<br />')">
        </p>
      </div>
  </div>
</template>
<style scoped>

.question-answer-faq-page {
  box-shadow: var(--boxshadow);
  border: var(--light-blue-border);
}

.question-answer-no-faq-page {
  overflow: hidden;
  height: fit-content;
}

.question-answer {
  margin-left: 3rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  cursor: pointer;
}
.question-answer::after{
  content: "";
  display: block;
  height: 2px;
  width: 98%;
  margin: auto;
  background-color: var(--color-light-blue-primary);
}
.question-answer:deep(svg) {
  flex-shrink: 0;
  display: flex;
  border: var(--orange-border);
  padding: 2px;
  border-radius: 50%;
}
.isActive {
  background-color: var(--color-light-blue-primary);
}
.isActive:deep(svg) {
  background-color: var(--color-orange);
  transition: var(--transition-all);
}
.isActive.isActive:deep(svg) > rect {
  fill: var(--color-white);
}
.isActive:deep(svg) > rect:nth-child(2) {
  transition: var(--transition-all);
  transform: rotate(90deg);
  transform-origin: center;
}
.isInactive:deep(svg) > rect:nth-child(2) {
  transform-origin: center;
  transition: var(--transition-all);
}
.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 1.5rem;
  transition: var(--transition-all);
}
.question h3 {
  font-size: clamp(1rem, 2vw, 1.2rem);
}
.answer {
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  opacity: 0;
  max-height: 0;
}
.open{
  max-height: 80rem;
  opacity: 1;
}
.answer p{
  padding: 2rem;
}
@media screen and (min-width: 1024px) {
  .question{
    min-height: 5rem;
  }
}
@media screen and (max-width: 1024px) {
  .question-answer {
    margin-left: 0;
  }
}
</style>
