<script setup lang="ts">
import { useField } from "vee-validate";
const props = defineProps<{
  name: string;
  label?: string;
  defaultValue?: any;
}>();
defineOptions({
  inheritAttrs: false,
});

const { value, errorMessage } = useField(() => props.name);
if (props.defaultValue) {
  value.value = props.defaultValue;
}

</script>
<template>
  <div class="categorie-field">
    <label :for="name" v-if="label">{{ label }}</label>
    <div class="select-field">
      <select :name="name" v-model="value" v-bind="$attrs">
        <slot></slot>
      </select>
    </div>
    <p class="error" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>
<style scoped>
.categorie-field {
  font-size: clamp(0.5rem, 1vw, 0.8rem);
  font-weight: 600;
  color: var(--color-dark-blue-secondary);
}

.categorie-field select {
  width: 100%;
  background-color: transparent;
  color: var(--color-dark-blue-secondary);
}

.categorie-field select:first-letter {
  text-transform: capitalize;
}

.select-field {
  margin-top: 0.5rem;
  background-color: var(--color-white);
  padding: 0.5rem;
  border-radius: 15px;
  border: 2px var(--color-dark-blue-primary) solid;
}
</style>
