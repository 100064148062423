<script setup lang="ts">
import { useField } from "vee-validate";
const props = defineProps<{
  name: string;
  label?: string;
}>();
defineOptions({
  inheritAttrs: false,
});

const { value, errorMessage } = useField(() => props.name);
</script>
<template>
  <div class="input">
    <label :for="name" v-if="label">{{ label }}</label>
    <input
      v-model="value"
      v-bind="$attrs"
      :class="[errorMessage ? 'error-field' : '']"
      :name="name"
    />
    <p class="error" v-if="errorMessage">{{ errorMessage }}</p>
  </div>
</template>
<style scoped>
.input input {
  padding: 0.8rem 1rem;
  background-color: var(--color-white);
  border-radius: 15px;
  border: 2px var(--color-dark-blue-primary) solid;
  width: 100%;
}
.input label {
  display: block;
  color: var(--color-dark-blue-secondary);
  font-weight: 600;
  font-size: 0.8rem;
  padding-left: 0.5rem;
}
</style>
