<script setup lang='ts'>
import InscriptionSection from 'jrmc/vue/prelogin/components/Inscription/InscriptionSection.vue';
import PreInscriptionSection from "jrmc/vue/prelogin/components/PreInscription/PreInscriptionSection.vue";
import { setMetaTags } from "jrmc/vue/prelogin/seo/seo.server";
import { provide, ref } from 'vue';
import {useRoute} from "vue-router";
const isSelected = ref<boolean>(false)
const IsNonInsuranceUser = ref<null|boolean>(null)
provide('isNonInsuranceUser', IsNonInsuranceUser);
const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
</script>
<template>
    <div>
        <Transition name="preregister" mode="out-in">
            <PreInscriptionSection v-if="!isSelected" v-model:is-selected="isSelected" v-model:is-non-insurance-user="IsNonInsuranceUser"/>
            <InscriptionSection v-else-if="isSelected"/>
        </Transition>
    </div>
</template>
<style scoped>
</style>
