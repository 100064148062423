import { HttpClient } from "jrmc/vue/shared/api/http-client";
import { GenericResponse, Inscription, RegisterResponse, ResponseFromOrias } from "jrmc/vue/prelogin/types/inscription-form";

export default new class RegisterApi extends HttpClient{
    public constructor(){
        super("/api/v1/registration")
    }
    async register(registerForm : Inscription){
        return this.instance.post<RegisterResponse>('',registerForm);
    }
    async registerFromOrias(orias: string){
        return this.instance.get<ResponseFromOrias>(`/from_orias/${orias}`)
    }
    async activateAccount(confirmationToken: string){
        return this.instance.post<GenericResponse>('/confirm', {confirmationToken});
    }
}