import { UserType } from "jrmc/vue/prelogin/enums/user-type";

export class InscriptionForm {
  userType: UserType
  email: string;
  orias?: string;
  firstname: string;
  lastname: string;
  address: string;
  postCode: string;
  city: string;
  commercialName?: string;
  plainPasswordFirst: string;
  plainPasswordSecond: string;
  phone: string;
  gclid?: string;
  cgvAccepted: boolean;
  affiliation?: number;

  constructor() {
    this.userType = UserType.PROFESSIONAL;
    this.email = "";
    this.firstname = "";
    this.lastname = "";
    this.address = "";
    this.postCode = "";
    this.city = "";
    this.commercialName = "";
    this.plainPasswordFirst = "";
    this.plainPasswordSecond = "";
    this.phone = "";
    this.cgvAccepted = false;

    const url = new URL(window.location.href);
    const gclid = localStorage.getItem('gclid');
    if (gclid !== null) {
      this.gclid = gclid;
    }
  }
}
