<script setup lang="ts">
import ContactSection from "jrmc/vue/prelogin/components/Contact/ContactSection.vue";
import CustomerReviewSection from 'jrmc/vue/prelogin/components/CustomerReview/CustomerReviewSection.vue'
import { setMetaTags } from "jrmc/vue/prelogin/seo/seo.server";
import { useRoute } from "vue-router";

const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
</script>
<template>
  <div class="contact-wrapper">
    <ContactSection />
    <CustomerReviewSection/>
  </div>
</template>
<style scoped>
.contact-wrapper{
  padding-bottom: 2rem;
}
</style>
