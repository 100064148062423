<script setup lang="ts">
import FormImage from "jrmc/vue/prelogin/components/FormImage.vue";
import ContactForm from "jrmc/vue/prelogin/components/Contact/ContactForm.vue";

const imageFormData = {
  imageUrl: "contact_woman.webp",
  left: true,
  title: "Besoin d'aide ?",
};
</script>

<template>
  <section class="contact-section">
    <div class="container contact-container">
      <FormImage v-bind="imageFormData" />
      <ContactForm />
    </div>
  </section>
</template>

<style scoped>
.contact-section {
  min-height: 100vh;
  height: 100%;
  background-image: url("../../assets/images/waves_pattern.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: var(--color-light-blue-primary);
  background-size: 50%;
  padding-bottom: 2rem;
}
.contact-container {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .glassmorph-container {
    display: none;
  }
  .contact-container {
    justify-content: center;
  }
}
</style>
