<script setup lang="ts">
import { Routes } from "../../enums/routes";
import ServiceCardComponent from "./ServiceCardComponent.vue";
import { ids } from "jrmc/vue/prelogin/enums/service";
const serviceCardContent = [
  {
    title: "Résiliations de contrats d’assurance",
    icon: 'cancellation_letter_icon.svg',
    sectionId: ids.RESILIATION,
  },
  {
    title: "Changement d’assurance emprunteur",
    icon: 'Picto_Carte_Assurance_Emprunteur.svg',
    sectionId: ids.ASSURANCE,
  },
  {
    title: "Tous vos autres courriers",
    icon: 'other_letter_icon.svg',
    sectionId: ids.COURRIER,
  },
];
</script>

<template>
    <div class="container">
      <div class="services-header-container">
        <div class="text">
          <p>Rompre par lettre papier n’est plus à la mode. Avec JRMC, nous vous proposons de rompre par LRE !</p>
          <p>
            JeResilieMonContrat.com est une solution digitale innovante qui vous
            permet de résilier en quelques clics <br />
            les anciens contrats d'assurance de tous vos nouveaux clients !
          </p>
        </div>
        <div class="cta">
          <RouterLink class="btn btn-blue" :to="{name: Routes.CONTACT}">Démonstration</RouterLink>
          <RouterLink class="btn btn-transparent-blue" :to="{name: Routes.INSCRIPTION}">S'inscrire</RouterLink>
        </div>
      </div>
      <div class="service-container">
        <ServiceCardComponent
          v-for="card in serviceCardContent"
          v-bind="card"
          :key="card.title"
        >
        </ServiceCardComponent>
      </div>
    </div>
</template>

<style scoped>
.container p {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: var(--color-dark-blue-secondary);
}
.service-container {
  margin-top: 4rem;
  display: flex;
  gap: 2rem;
}
.services-header-container{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}
.services-header-container .cta{
  display: flex;
  gap: 1rem;
}
@media screen and (max-width: 1024px) {
  .service-container {
    flex-wrap: wrap;
  }
}
</style>
