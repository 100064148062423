<script setup lang="ts">
import GenerationStepComponent from "./GenerationStepComponent.vue";

const steps = [
  {
    title: "Génération",
    img: "Picto_Timeline_Enveloppe_Generation.svg",
    text: `Simplifiez la création de vos courriers de résiliation grâce à notre interface intuitive.
    Conçue pour être conviviale, elle vous permet de générer des lettres de résiliation conformes juridiquement et personnalisées en quelques clics.`,
  },
  {
    title: "Envoi",
    img: "Picto_Timeline_Avion_Envoi.svg",
    text: `Envoyez vos lettres recommandées électroniques avec facilité.
          Expédiez vos recommandés en ligne en 1 seul clic à tout moment ou en planifiant leur envoi à une date spécifique. `,
  },
  {
    title: "Réception",
    img: "Picto_Timeline_Enveloppe_Ouverte_Reception.svg",
    text: `Assurez la bonne réception de vos courriers grâce à notre solution JRMC.
          Soyez sereins, nos équipes expertes et rigoureuses veillent à ce que chacun de vos plis soient relevés par leurs destinataires.  `,
  },
  {
    title: "Suivi",
    img: "Picto_Timeline_Valide_Suivi.svg",
    text: `Suivez en temps réel l’acheminement de vos lettres de résiliation.
            Grâce à notre tableau de bord complet, consultez l’état de vos lettres recommandées à chaque étape du processus jusqu’à leur réception. `,
  },
];
</script>
<template>
  <section>
    <div class="container">
      <h2>Un parcours simplifié : de la génération à la résiliation en 4 étapes</h2>
      <div class="steps-container">
        <GenerationStepComponent
          v-for="step in steps"
          v-bind="step"
          :key="step.title"
        />
      </div>
    </div>
  </section>
</template>
<style scoped>
.steps-container {
  margin-top: 4rem;
  position: relative;
  display: flex;
  gap: 2rem;
  justify-content: space-around;
}
.steps-container::after {
  position: absolute;
  top: 2.2rem;
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--color-orange);
}
@media screen and (max-width: 1024px) {
  .steps-container {
    flex-wrap: wrap;
  }
  .steps-container::after {
    display: none;
  }
}
</style>
