import { HttpClient } from "jrmc/vue/shared/api/http-client";
import {Group} from "jrmc/vue/prelogin/types/group";

export default new class GroupApi extends HttpClient{
  public constructor(){
    super("/api/v1")
  }
  async getAffiliations(){
    return this.instance.get<Group[]>('/affiliations');
  }
}
