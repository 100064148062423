<script setup lang="ts">
import InscriptionFormFirstBloc from "jrmc/vue/prelogin/components/Inscription/InscriptionFormFirstBloc.vue";
import InscriptionFormSecondBloc from "jrmc/vue/prelogin/components/Inscription/InscriptionFormSecondBloc.vue";
import OriasForm from "jrmc/vue/prelogin/components/Inscription/OriasForm.vue";
import RegistrationSucces from "jrmc/vue/prelogin/components/Inscription/RegistrationSucces.vue";
import RegisterApi from "jrmc/vue/prelogin/api/register";
import {inject, Ref, ref} from "vue";
import {useForm} from "vee-validate";
import {InscriptionForm} from "jrmc/vue/prelogin/models/InscriptionForm";
import {AxiosError} from "axios";
import {ErrorRegistration, FieldsErrors, UserInfoFromOrias,} from "jrmc/vue/prelogin/types/inscription-form";
import {RegistrationErrors} from "jrmc/vue/prelogin/enums/register";
import Modal from "jrmc/vue/prelogin/components/Modal.vue";
import {useModal} from "jrmc/vue/prelogin/composable/useModal";
import analytics from "jrmc/vue/shared/services/analytics";
import SweetAlert2 from "sweetalert2";
import {UserType} from "jrmc/vue/prelogin/enums/user-type";
import {required} from '@vee-validate/rules';

const index = ref(0);
const registrationSucces = ref(false);
const isOriasLoading = ref(false);
const isNonInsuranceUser : Ref<boolean | null> = inject('isNonInsuranceUser') as Ref<boolean | null>;
const { isOpen, close, open } = useModal();
const initialValues = new InscriptionForm();
const { handleSubmit, values, isSubmitting, setFieldError, setFieldValue } =
  useForm<InscriptionForm>({
    initialValues,
    validationSchema: {
      email: 'email|required',
      lastname: 'required',
      firstname: 'required',
      commercialName: (val: string) => {
        if (!required(val) && (isNonInsuranceUser.value === false || values.userType === UserType.PROFESSIONAL)) {
          return 'Veuillez saisir un nom commercial';
        } else {
          return true;
        }
      },
      plainPasswordFirst: 'password|required',
      plainPasswordSecond: 'confirmed:@plainPasswordFirst|required',
      address: 'required|max:38',
      postCode: 'required|digits:5',
      city: 'required',
      phone: 'phone|required',
      portable: 'phone',
      cgvAccepted: (val: boolean) =>
        val
          ? true
          : "Pour continuer, vous devez accepter les CGU et CGV",
    },
  });
const transformUserObject = (user: InscriptionForm) => {
  return {
    email: user.email,
    orias: user.orias,
    firstname: user.firstname,
    lastname: user.lastname,
    address: user.address,
    postCode: user.postCode,
    city: user.city,
    nonInsuranceUser : isNonInsuranceUser.value as boolean,
    commercialName: user.commercialName,
    plainPassword: {
      first: user.plainPasswordFirst,
      second: user.plainPasswordSecond,
    },
    phone: user.phone,
    affiliation: user.affiliation,
    cgvAccepted: user.cgvAccepted,
    gclid: user.gclid
  };
};
const hasFirstPartErrors = (errors: FieldsErrors) => {
  const firstPartKeys: (keyof FieldsErrors)[] = [
    "firstname",
    "lastname",
    "commercialName",
    "email",
    "plainPasswordFirst",
    "plainPasswordSecond",
  ];
  return firstPartKeys.some((key) => errors[key]);
};
const assignValueFromOrias = (userInfo: UserInfoFromOrias) => {
  const keys = Object.keys(userInfo) as (keyof UserInfoFromOrias)[];
  keys.forEach((key) => {
    setFieldValue(key, userInfo[key]);
  });
};
const handleClickContinue = (newIndex: number) => {
  index.value = newIndex;
};
const handleClickOrias = async () => {
  if (values.orias) {
    try {
      isOriasLoading.value = true;
      const { userInfo } = await RegisterApi.registerFromOrias(values.orias);
      assignValueFromOrias(userInfo);
      isOriasLoading.value = false;
      close();
    } catch (e) {
      setFieldError(
        "orias",
        "Aucune information trouvée avec ce numéro ORIAS."
      );
      isOriasLoading.value = false;
    }
  } else {
    setFieldError("orias", "veuillez entrer un orias");
  }
};

function userTypeForGa4(user: any) {
  if (isNonInsuranceUser) {
    if (user.commercialName) {
      return 'PROFESSIONAL'
    } else {
      return 'INDIVIDUAL'
    }
  } else {
    return 'INSURANCE'
  }
}

const onSubmitInscription = handleSubmit(
  async (values) => {
    try {
      const user = transformUserObject(values);

      await RegisterApi.register(user);

      if (!import.meta.env.SSR) {
        localStorage.removeItem('gclid');
      }

      analytics.event('sign_up', {
        'user_type': userTypeForGa4(user),
      })

      registrationSucces.value = true;
    } catch (e) {
      const axiosError = e as AxiosError;
      const errors = axiosError.response?.data as ErrorRegistration;
      const errorsCodes = errors.error_codes;
      if (errorsCodes && errorsCodes[0] === RegistrationErrors.NOT_UNIQUE) {
        setFieldError("email", "Ce nom d'utilisateur est déjà utilisé");
        handleClickContinue(0);
      } else {
        await SweetAlert2.fire(
          'Erreur',
          'Une erreur est survenue lors de votre inscription, veuillez réessayer ou nous contacter si cela persiste',
          'error'
        );
      }
    }
  },
  ({ errors }) => {
    if (hasFirstPartErrors(errors)) {
      handleClickContinue(0);
    } else {
      handleClickContinue(1);
    }
  }
);
</script>
<template>
  <div v-if="registrationSucces" class="registration-succes-section">
    <RegistrationSucces />
  </div>
  <div v-else class="inscription-form-container">
    <h1>Inscription</h1>
    <form @submit="onSubmitInscription">
      <Transition name="inscription" mode="out-in">
        <InscriptionFormFirstBloc
          :isNonInsuranceUser="isNonInsuranceUser"
          :userType="values.userType"
          v-show="index === 0"
          @handle-click-continue="handleClickContinue"
          @open="open"
        />
      </Transition>
      <Transition name="inscription" mode="out-in">
        <InscriptionFormSecondBloc
          v-show="index === 1"
          @handle-click-continue="handleClickContinue"
          :is-loading="isSubmitting"
          :isNonInsuranceUser="isNonInsuranceUser"
        />
      </Transition>
      <div class="selectors">
        <span
          @click="handleClickContinue(0)"
          :class="[index === 0 ? 'active' : '']"
        ></span>
        <span
          @click="handleClickContinue(1)"
          :class="[index === 1 ? 'active' : '']"
        ></span>
      </div>
      <Modal :isOpen="isOpen" @close="close">
        <OriasForm
          @handle-click-orias="handleClickOrias"
          :isOriasLoading="isOriasLoading"
        />
      </Modal>
    </form>
  </div>
</template>
<style scoped>
.inscription-form-container {
  width: 70%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
}

.inscription-form-container h1 {
  text-align: center;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}

.inscription-form-container form {
  min-height: 22rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.selectors {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.selectors span {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  background-color: var(--color-dark-blue-primary);
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
}

.selectors .active {
  opacity: 1;
}

.cta,
.cta-inscription {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 0.6rem;
}

.cta button,
.cta-inscription button {
  width: 100%;
}

.cta-inscription p {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .inscription-form-container {
    max-width: none;
    width: 100%;
  }
}
</style>
