export enum Routes {
  HOME = "home",
  ABOUT = "about",
  SERVICES = "services",
  TARIFS = "tarifs",
  CONTACT = "contact",
  FAQ = "faq",
  CONNEXION = "connexion",
  INSCRIPTION = "inscription",
  REINITIALISATION = "reinitialisation",
  REINITIALISATION_SUBMISSION = "submission",
  ACTIVATION = "activation",
  LEGAL = "mentions-legales",
  PRIVACY = "politique-de-confidentialite",
  COOKIES = "politique-de-cookies",
}
