<script setup lang="ts">
import { Faq, FaqCategories } from "jrmc/vue/prelogin/types/faq";
import FaqComponent from "jrmc/vue/prelogin/components/Faq/FaqComponent.vue";

const props = defineProps<{
  faq: Faq[];
  faqCategories: FaqCategories[];
}>();

const filterByCategory = (categoryId: number) => {
  const filteredQuestion = props.faq.filter(
    (question) => question.category.id === categoryId
  );
  return filteredQuestion;
};
</script>

<template>
  <section>
    <div class="container faq-section">
      <FaqComponent
        v-for="faqCategory in faqCategories"
        :key="faqCategory.id"
        :questions="filterByCategory(faqCategory.id)"
        :category="faqCategory"
      />
    </div>
  </section>
</template>

<style scoped>
/* Styles spécifiques au composant */
</style>
