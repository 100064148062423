<script setup lang="ts">
import { useHeadSafe } from "@unhead/vue";
import { siteName, titleSeparator } from "jrmc/vue/prelogin/seo/seo.server";

if (!import.meta.env.SSR) {
  const url = new URL(window.location.href);
  if (url.searchParams.has('gclid') && url.searchParams.get('gclid') !== null) {
    localStorage.setItem('gclid', url.searchParams.get('gclid') as string);
  }
}

useHeadSafe({
  templateParams: {
    site: {
      name: siteName
    },
    separator: titleSeparator
  }
})
</script>
<template>
  <router-view v-slot="{ Component }">
    <Suspense>
      <component :is="Component" />
    </Suspense>
  </router-view>
</template>
