import { useSeoMeta, UseSeoMetaInput } from "@unhead/vue";
import { useSSRContext } from "vue";
import { useRouter } from "vue-router";

export const siteName = 'JRMC'
export const titleSeparator = '|'

export function setOgUrl(url: string) {
  let ctx = undefined;
  if (import.meta.env.SSR) {
    ctx = useSSRContext() as Record<string, any>;
    return `${ctx["VITE_SSR_API_URL"]}${url}`;
  }
  return "client";
}
export function setOgImage() {
  let ctx = undefined;
  if (import.meta.env.SSR) {
    ctx = useSSRContext() as Record<string, any>;
    return `${ctx["VITE_SSR_API_URL"]}/static/logo.png`;
  }
  return "";
}

export function setMetaTags(metaTags: UseSeoMetaInput) {
  const router = useRouter();
  const currentRoute = router.currentRoute.value.path;
  useSeoMeta({
    title: metaTags.title,
    titleTemplate: "%s  %separator %site.name ",
    description: metaTags.description,
    ogDescription: metaTags.description,
    ogSiteName: "%site.name",
    ogType: "website",
    ogTitle: "%s  %separator %site.name ",
    ogImage: setOgImage(),
    ogUrl: setOgUrl(currentRoute),
    twitterCard: "summary_large_image",
    twitterTitle: "%s  %separator %site.name ",
    twitterDescription: metaTags.description,
    twitterImage: setOgImage(),
  });
}
