<script setup lang="ts">
import InputPassword from "jrmc/vue/prelogin/components/Fields/InputPassword.vue";
import { useForm } from "vee-validate";
import ResetPasswordApi from "jrmc/vue/prelogin/api/reset-password";
import { useRoute } from "vue-router";
import { ref } from "vue";
import { Routes } from "jrmc/vue/prelogin/enums/routes";
import SweetAlert2 from "sweetalert2";

const isSubmitted = ref(false);
const error = ref(false);
const router = useRoute();
const { handleSubmit, isSubmitting, setFieldError } = useForm({
  validationSchema: {
    first: 'password|required',
    second: 'confirmed:@first|required',
  },
});
const handleSubmitResetPassword = handleSubmit(async (values) => {
  const { token } = router.params;
  const plainPassword = {
    first: values.first,
    second: values.second,
  };
  try {
    await ResetPasswordApi.resetPassword(
      token as string,
      plainPassword
    );
    isSubmitted.value = true;
  } catch (e) {
    await SweetAlert2.fire(
      'Erreur',
      'Une erreur est survenue, veuillez réessayer ou nous contacter si cela persiste',
      'error'
    );
    error.value = true;
  }
});
</script>
<template>
  <div v-if="isSubmitted" class="container succes-response">
    <p>Votre mot de passe a été réinitialisé avec succès.</p>
    <RouterLink :to="{ name: Routes.CONNEXION }" class="btn btn-blue"
      >Se connecter</RouterLink
    >
  </div>
  <div v-else-if="error" class="container error-response">
    <p>Il semblerait que le token ait déjà été utilisé ou soit invalide</p>
    <RouterLink :to="{ name: Routes.HOME }" class="btn btn-blue"
      >Retour</RouterLink
    >
  </div>
  <div v-else class="container input-reset-container">
    <h1>Veuillez redefinir votre mot de passe</h1>
    <form @submit="handleSubmitResetPassword">
      <div class="inputs">
        <InputPassword :name="'first'" :label="'Votre nouveau mot de passe'" />
        <InputPassword
          :name="'second'"
          :label="'Confirmation de votre nouveau mot de passe'"
        />
      </div>
      <button
        :class="[isSubmitting ? 'loading' : '', 'btn-lg', 'btn-blue']"
        :disabled="isSubmitting"
      >
        Changer de mot de passe
      </button>
    </form>
  </div>
</template>
<style scoped>
.input-reset-container {
  max-width: 50%;
}

.input-reset-container h1 {
  text-align: center;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}

.input-reset-container form {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.input-reset-container button {
  font-size: 1rem;
}
.inputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.succes-response,
.error-response {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.succes-response p,
.error-response p {
  font-weight: 700;
  font-size: 1.2rem;
}
@media screen and (max-width: 1024px) {
  .input-reset-container {
    max-width: 100%;
  }
}
</style>
