<script setup lang="ts">
import ConnexionSection from 'jrmc/vue/prelogin/components/Connexion/ConnexionSection.vue';
import { setMetaTags } from 'jrmc/vue/prelogin/seo/seo.server';
import {useRoute} from "vue-router";
const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
</script>

<template>
    <ConnexionSection/>
</template>
<style scoped>
</style>
