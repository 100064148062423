import {defineRule} from 'vee-validate';
import {digits, email, max, min, required} from '@vee-validate/rules';

defineRule('required', (value: any) => {
  if (!required(value)) {
    return 'Ce champ est obligatoire'
  }
  return true
});

defineRule('boolean', (value: any) => {
  if (value !== true && value !== false) {
    return 'Ce champ est obligatoire'
  }
  return true
});

defineRule('max', (value: any, [length]: Array<any>) => {
  if (!max(value, [length])) {
    return `Ce champ ne doit pas dépasser ${length} caractères`
  }
  return true
});

defineRule('min', (value: any, [length]: Array<any>) => {
  if (!min(value, [length])) {
    return `Ce champ doit contenir au moins ${length} caractères`
  }
  return true
});

defineRule('email', (value: any) => {
  if (!email(value)) {
    return `${value} n'est pas un email valide.`
  }
  return true
});

defineRule('digits', (value: any, [length]: Array<any>) => {
  if (!digits(value, [length])) {
    return `Ce champ doit comporter ${length} chiffres`
  }
  return true
});

defineRule("addressLimit", (value: any, {}) => {
  if (!value) {
    return true;
  }

  let line = value.split("\n");
  if (line.length > 4) {
    return "Ce champ ne doit pas dépasser 4 lignes et 38 caractères par ligne.";
  }

  for (let i = 0; i < line.length; i++) {
    if (line[i].length > 38) {
      return "Ce champ ne doit pas dépasser 4 lignes et 38 caractères par ligne.";
    }
  }

  return true;
});

defineRule("phone", (value: any, {}) => {
  if (!value) {
    return true;
  }

  if (/^\+?[0-9]+$/.test(value) && min(value, [10]) && max(value, [15])) {
    return true;
  } else {
    return "Le numéro de téléphone doit être valide (avec ou sans préfixe international)";
  }
});

defineRule("password", (value: any, {}) => {
  if (!value) {
    return true;
  }

  const lengthRegex = /^.{9,}$/;
  const uppercaseRegex = /[A-Z]/;
  const downcaseRegex = /[a-z]/;
  const digitRegex = /\d/;

  if (lengthRegex.test(value) && downcaseRegex.test(value) && uppercaseRegex.test(value) && digitRegex.test(value)) {
    return true;
  } else {
    return 'Votre mot de passe doit contenir au moins 9 caractères, avec au moins 1 minuscule, 1 majuscule et 1 chiffre';
  }
});

defineRule("confirmed", (value: any, [target]: Array<any>) => {
  if (value === target) {
    return true;
  }
  return 'Les mots de passe doivent correspondre';
});
