<script setup lang='ts'>
import CustomerReviewCard from 'jrmc/vue/prelogin/components/CustomerReview/CustomerReviewCard.vue';
import { ref } from 'vue';
const reviews = [
    {
        stars: 5,
        review: `Nous sommes courtiers et apprécions le fonctionnement de “JeResilieMonContrat.com". Très fluide, intuitif avec un suivi qui nous simplifie la vie ! Utilisable à toutes heures, fini la course pour arriver avant la fermeture de la poste.`,
        name : 'Isabelle RDC'
    },
    {
        stars: 5,
        review: `Nous utilisons depuis quelques années ce service et nous en sommes ravis. Le système est simple et rapide à l'usage. C'est également très bien pour programmer les envois. Merci.`,
        name : 'Séverine David'
    },
    {
        stars: 5,
        review: `Service indispensable pour accompagner nos clients dans la résiliation de leurs contrats.Simple, ergonomique, efficace !`,
        name : 'Nicolas Frachon'
    },
    {
        stars: 5,
        review: `J'utilise ce service depuis de nombreuses années et cela est très fiable. Je recommande afin de gagner un maximum de temps et de frais postaux. Lorsque nous avons un souci il répond toujours présent en nous recontactant je recommande.`,
        name : 'Anthony Gualdi'
    },
    {
        stars: 5,
        review: `Nous utilisions ce site depuis maintenant plusieurs années. c'est un gain de temps et d'argent.je recommande !`,
        name : 'Melissa Rei'
    },
    {
        stars: 5,
        review: `Excellent site, très pratique, permet de résilier sans se déplacer, et pour moins cher qu'à la poste.`,
        name : 'Laurent Lefevre'
    }
]
const index = ref(0);
const handleClickNext = ()=>{
    index.value = (index.value + 1) % 2
}
const handleClickPrevious = ()=>{
    if(index.value > 0){
        index.value = index.value - 1
    }
}
</script>
<template>
    <div class="customer-wrapper">
        <div class="customer-review-container" :style="{transform: `translateX(-${(index*50)}%)`}">
            <CustomerReviewCard v-for="review in reviews" v-bind="review"/>
        </div>
        <div class="buttons">
            <button @click="handleClickPrevious">
                <
            </button>
            <button @click="handleClickNext">
                >
            </button>
        </div>
    </div>
</template>
<style scoped>
.customer-wrapper{
    overflow: hidden;
    margin-top: 2rem;
    width: 100%;
}
.customer-review-container{
    padding: 2rem 1rem;
    transition: var(--transition-all);
    display: grid;
    grid-template-columns: repeat(6,1fr);
    gap: 1rem;
    width: 200%;
}
.buttons{
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 60%;
    width: 90%;
    left: 5%;
}
.buttons button{
    background-color: var(--color-light-blue-primary);
    color: var(--color-white);
    font-size: 2rem;
    border-radius: 50%;
    width: 3rem;
    aspect-ratio: 1/1;
    cursor: pointer;
}
@media screen and (max-width: 1024px) {
    .buttons{
        display: none;
    }
    .customer-review-container{
        width: 300%;
    }
    .customer-wrapper{
        overflow-x: scroll;
    }
}
@media screen and (max-width: 650px) {
    .customer-review-container{
        width: 600%;
    }
}
</style>