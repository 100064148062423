<script setup lang="ts">
import Input from "jrmc/vue/prelogin/components/Fields/Input.vue";
import InputPassword from "jrmc/vue/prelogin/components/Fields/InputPassword.vue";
import {useForm} from "vee-validate";
import {ref} from "vue";
import {ConnexionForm} from "jrmc/vue/prelogin/models/ConnexionForm";
import LoginApi from "jrmc/vue/prelogin/api/login";
import {AxiosError} from "axios";
import {ConnexionResponse} from "jrmc/vue/prelogin/types/connexion-form";
import {ErrorReason} from "jrmc/vue/prelogin/enums/login";
import {Routes} from "jrmc/vue/prelogin/enums/routes";
import analytics from "jrmc/vue/shared/services/analytics";
import SweetAlert2 from "sweetalert2";

const initialValues: ConnexionForm = new ConnexionForm();
const loginResponse = ref<ConnexionResponse | null>(null);
const isSubmitting = ref(false);
const { handleSubmit } = useForm<ConnexionForm>({
  initialValues,
  validationSchema: {
    email: 'email|required',
    password: 'required',
  },
});
const onSubmitLogin = handleSubmit(async (values) => {
  try {
    isSubmitting.value = true;
    const response = await LoginApi.login(values);
    if (response.success) {
      analytics.event('login')

      window.location.href = response.redirectUrl as string;
    }
  } catch (e) {
    const axiosError = e as AxiosError;
    if (axiosError.response && axiosError.response.status === 401) {
      loginResponse.value = axiosError.response?.data as ConnexionResponse;

      if (loginResponse.value.redirectUrl) {
        window.location.href = loginResponse.value.redirectUrl as string;
      }
    } else {
      // We have an unexpected error like an HTTP 500
      await SweetAlert2.fire(
        'Erreur',
        'Une erreur est survenue lors de la connexion, veuillez réessayer ou nous contacter si cela persiste',
        'error'
      );
    }

    isSubmitting.value = false;
  }
});
</script>

<template>
  <div class="connexion-form-container">
    <h1>Connexion</h1>
    <form @submit="onSubmitLogin">
      <p class="error" v-if="loginResponse?.reason === ErrorReason.CLASSIC">
        Nom d'utilisateur ou mot de passe incorrect
      </p>
      <p class="error" v-else-if="loginResponse?.reason === ErrorReason.BRUTEFORCE">
        Trop de tentatives, merci de réessayer dans un moment.
      </p>
      <Input
        :class="[
          loginResponse?.reason === ErrorReason.CLASSIC ? 'error-field' : '',
        ]"
        :name="'email'"
        :type="'text'"
        :placeholder="'Adresse email'"
        autocomplete="username"
      />
      <div class="password-field">
        <InputPassword
          :class="[
            loginResponse?.reason === ErrorReason.CLASSIC ? 'error-field' : '',
          ]"
          :name="'password'"
          :placeholder="'Mot de passe'"
          autocomplete="current-password"
        />
        <RouterLink :to="{ name: Routes.REINITIALISATION }"
          >Mot de passe oublié ?</RouterLink
        >
      </div>
      <div class="cta">
        <button
          :class="[isSubmitting ? 'loading' : '', 'btn-lg', 'btn-blue']"
          :disabled="isSubmitting"
        >
          Se connecter
        </button>
        <RouterLink :to="{ name: Routes.INSCRIPTION }" class="underline"
          >Inscription</RouterLink
        >
      </div>
    </form>
  </div>
</template>

<style scoped>
.connexion-form-container {
  width: 70%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
  margin-top: 5%;
}

.connexion-form-container h1 {
  text-align: center;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
}

.password-field {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.password-field > a {
  font-size: 0.8rem;
  font-weight: 400;
  text-align: right;
  width: fit-content;
  align-self: flex-end;
}

.cta {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  align-items: stretch;
  gap: 0.5rem;
}

.cta > button {
  font-size: 1rem;
}

.cta > a {
  font-size: 0.8rem;
  font-weight: 700;
  text-align: center;
}
</style>
