<script setup lang="ts"></script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="63"
    height="63"
    viewBox="0 0 63 63"
    fill="none"
  >
    <path
      d="M63 60H8C5.23857 60 3 57.7614 3 55V1.78814e-06"
      stroke="#F39422"
      stroke-width="5"
    />
  </svg>
</template>
