<script setup lang="ts">
import FormImage from "jrmc/vue/prelogin/components/FormImage.vue";
import InscriptionForm from "jrmc/vue/prelogin/components/Inscription/InscriptionForm.vue";
import QualityMapComponent from "jrmc/vue/prelogin/components/QualityMap/QualityMapComponent.vue";
import PartnersComponent from "jrmc/vue/prelogin/components/PartnersComponent.vue";
import CustomerReviewSection from 'jrmc/vue/prelogin/components/CustomerReview/CustomerReviewSection.vue'
const imageFormData = {
  imageUrl: "inscription_man.webp",
  left: true,
  title: "Résiliez en 1 clic dès maintenant",
};
</script>

<template>
  <div class="inscription-wrapper">
    <div class="inscription-section">
      <div class="container inscription-container">
        <FormImage v-bind="imageFormData" />
        <InscriptionForm />
      </div>
    </div>
    <div class="container quality-container">
      <QualityMapComponent/>
    </div>
    <PartnersComponent
    :title="'Plus de 50 partenaires maîtrisent l’art de la résiliation'"
    :subtitle="'Des leaders du secteur de l\'assurance choisissent notre expertise pour optimiser et accélérer leurs procédures de résiliation'"
  />
  <CustomerReviewSection/>
  </div>
</template>

<style scoped>
.inscription-wrapper{
  padding-bottom: 2rem;
}
.inscription-section {
  display: flex;
  background-image: url("../../assets/images/waves_pattern.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: var(--color-light-blue-primary);
  background-size: 60%;
  padding: 2rem 0;
  min-height: calc(100vh - 230px);
}
.quality-container{
  margin: 4rem auto 6rem;
}
.inscription-container {
  display: flex;
  align-items: center;
  gap: 3rem;
}
@media screen and (max-width: 1024px) {
  .glassmorph-container {
    display: none;
  }
  .connexion-container {
    justify-content: center;
  }
}
</style>
