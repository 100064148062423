<script setup lang="ts">
</script>
<template>
  <section>
    <div class="container about-container">
      <p>
        Vous êtes un professionnel de l’assurance ? Notre solution vous permet de générer et d’envoyer facilement vos
        demandes de résiliation de contrats d’assurance par lettre recommandée électronique pour le compte de vos
        anciens et nouveaux clients dans le cadre d’une reprise de contrat.
      </p>
      <div class="jrmc-missions">
        <h2>La mission des équipes JRMC </h2>
        <p>
          La résiliation de contrats d’assurance est très souvent associée à une formalité administrative longue et fastidieuse.
          Chez JeResilieMonContrat.com, nous sommes pourtant convaincus que cela peut être simple et rapide !
        </p>
        <p>
          C’est le pari pris par JRMC, pionnier sur le marché de la résiliation de contrats d’assurance par lettre recommandée électronique, avec la création d’une solution digitale simple et intuitive qui vous permet de gagner en efficacité.
        </p>
        <p>
          En réinventant le processus de résiliation, notre solution vous libère du temps précieux pour vous concentrer sur l'essentiel : la relation client et le développement commercial.
        </p>
        <p>
          Nous croyons par ailleurs en une performance responsable. C’est pourquoi nous avons toujours choisi de privilégier les envois de courriers électroniques 100% dématérialisés.
        </p>
        <p>
          Constamment à l’écoute de ses clients, les équipes JRMC avancent à vos côtés depuis plus de 10 ans pour votre plus grande sérénité. Notre objectif : fini les prises de tête.
        </p>
      </div>
    </div>
  </section>
</template>
<style scoped>
.about-container {
  text-align: justify;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}
.jrmc-missions{
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

@media screen and (max-width: 1024px) {
  .about-container {
    flex-wrap: wrap;
  }
}
</style>
