<script setup lang='ts'>
import {setMetaTags} from "jrmc/vue/prelogin/seo/seo.server";
import {useRoute} from "vue-router";
import Hero from "jrmc/vue/prelogin/components/Hero.vue";

const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
</script>
<template>
  <Hero :title="'Politique de cookies'"/>
  <div class="page">
    <div class="container">
      <p class="title">En vigueur à compter du 16 décembre 2024 </p>
      <div class="block">
        <p>
          Cette politique de cookies décrit l'utilisation des cookies par la société CLIC’ISSIMO qui commercialise le
          service JeResilieMonContrat.com.
        </p>
        <p>
          L'objectif de la présente politique est de vous fournir, en tant qu’utilisateur des services JRMC ou des sites
          web conçus par la société CLIC’ISSIMO, des informations sur les raisons pour lesquelles nous utilisons des
          cookies et sur les options dont vous jouissez en matière de gestion de vos paramètres de cookies.
        </p>
        <h2>1. Qu'est-ce qu'un cookie et quoi sert il ?</h2>
        <p>Les cookies sont des fichiers textes déposés sur votre ordinateur, votre mobile ou votre tablette.
        </p>
        <p>
          Les cookies ne sont pas malveillants puisque ce ne sont ni des programmes, ni des virus et ils ne peuvent donc
          pas endommager votre ordinateur.
        </p>
        <p>
          Au contraire, ils sont même utiles car ils nous permettent de collecter des informations relatives à votre
          navigation, et de vous adresser des services adaptés à votre terminal pour une expérience encore plus optimale
          !
        </p>
        <p>
          Votre navigateur web les conservera pendant une certaine durée, et les renverra au site web chaque fois que
          vous vous y reconnecterez. </p>
      </div>
      <div class="block">
        <h2>2. Comment utilisons-nous les cookies ?</h2>
        <p>
          Les cookies remplissent de nombreuses fonctions différentes, comme vous permettre de naviguer efficacement
          entre les pages, de suivre le comportement de votre navigation en enregistrant des informations telles que le
          nombre de visites réalisées, la durée de la visite, le nombre de pages consultées, le taux de rebond, le temps
          de chargement, etc mais aussi de mémoriser vos préférences et améliorer votre expérience utilisateur.
        </p>
        <p>Pour votre parfaite information, nous avons choisi de classer les cookies que nous utilisons en 5 catégories
          :</p>

        <p><b>Les cookies nécessaires au bon fonctionnement du site</b> : Ces cookies sont nécessaires au bon
          fonctionnement de notre site web. Sans ces cookies, le site web ne fonctionne pas correctement (impossibilité
          de se connecter par exemple)</p>

        <p><b>Les cookies de sécurité</b> : Les cookies de sécurité permettent de stocker des informations liées à la
          sécurité, telles que l'authentification, la protection contre la fraude et d'autres moyens de protéger
          l'utilisateur.</p>

        <p><b>Les cookies de mesure d’audience</b> : Les cookies de mesure d'audience nous permettent de mesurer la
          performance de notre site web et de nos campagnes publicitaires. Nous les utilisons pour déterminer le nombre
          de visites et les sources de visites sur notre site web. Nous traitons les données obtenues par le biais de
          ces cookies de manière agrégée, sans utiliser d'identifiant qui permettrait d’identifier des utilisateurs
          spécifiques de notre site web. Si vous désactivez l'utilisation des cookies analytiques en relation avec votre
          visite, nous perdons la possibilité d'analyser les performances et d'optimiser nos mesures.</p>

        <p><b>Les cookies de personnalisation</b> : Les cookies de personnalisation peuvent utiliser des cookies tiers
          pour les aider à personnaliser le contenu et à suivre les utilisateurs sur différents sites web et appareils.
        </p>

        <p><b>Les cookies publicitaires</b> : Les cookies publicitaires sont utilisés par nous ou nos partenaires pour
          vous montrer des contenus ou des publicités pertinents à la fois sur notre site et sur des sites tiers. Cela
          nous permet de créer des profils basés sur vos intérêts, appelés profils pseudonymisés. Sur la base de ces
          informations, il n'est généralement pas possible de vous identifier directement en tant que personne, car
          seules des données pseudonymisées sont utilisées. Si vous n'y consentez pas, vous ne recevrez pas de contenus
          et de publicités adaptés à vos centres d'intérêt.</p>

        <p>
          La Société CLIC’ISSIMO est amenée à déposer des cookies tiers sur les terminaux de ses visiteurs. Ces cookies
          tiers ne peuvent pas être déposés sans le recueil du consentement préalable des visiteurs de son site
          internet.
        </p>
      </div>

      <div class="block">
        <h2>3. Options de gestion des cookies</h2>
        <p>Dans cette section, nous décrivons les différentes façons dont vous pouvez gérer vos préférences en matière
          de cookies, en fonction du type de cookie et de votre type d'appareil. </p>
        <p>La société CLIC’ISSIMO a mis en place un outil de paramétrage du consentement relatif aux cookies , aussi
          appelé « bandeau de cookies ».<br/>
          En cliquant sur l’icône jaune qui apparaît en bas à gauche de chaque page du site (logo en forme de « cookies
          »), vous pouvez gérer vos préférences en matière de cookies.
        </p>

        <p>
          Depuis cet outil de cookies et pour vous faciliter la gestion, nous avons choisi de catégoriser les cookies et
          de vous permettre, pour chacune des 5 catégories, de paramétrer le dépôt des cookies.
        </p>

        <p>
          Si vous choisissez de ne pas répondre au bandeau d’information ou de ne pas paramétrer le dépôt de cookies sur
          votre terminal avant utilisation de notre site internet, nous considérerons que vous avez refusé par défaut
          tous les cookies optionnels.
        </p>

        <p>
          Au contraire, les cookies nécessaires au bon fonctionnement de notre site internet sont des cookies exemptés
          du recueil du consentement et sont autorisés par défaut.
        </p>

        <p>
          Vous pouvez à tout moment choisir de désactiver les cookies non indispensables au bon fonctionnement du site.
          Pour cela, utilisez notre outil de paramétrage de cookies (depuis l’icône jaune en bas à gauche de chaque page
          du site).
        </p>

        <p>
          En revanche, veuillez noter que vous ne pourrez pas retirer votre consentement pour les cookies qui sont
          strictement nécessaires au fonctionnement du Service.
        </p>

        <p>
          Voici les procédures pour bloquer toute création de cookies à partir des options de votre navigateur :
          <ul>
            <li><a class="underline" href="https://support.google.com/chrome/answer/95647?hl=fr&hlrm=en">Chrome</a></li>
            <li><a class="underline"
                   href="https://support.microsoft.com/fr-fr/windows/g%C3%A9rer-les-cookies-dans-microsoft-edge-afficher-autoriser-bloquer-supprimer-et-utiliser-168dab11-0753-043d-7c16-ede5947fc64d#bkmk_blockallcookies">Edge</a>
            </li>
            <li><a class="underline"
                   href="https://support.mozilla.org/fr/kb/empecher-sites-web-stocker-cookies-donnees-site-firefox">Firefox</a>
            </li>
            <li><a class="underline" href="https://support.apple.com/fr-fr/HT201265">Safari</a></li>
          </ul>
        </p>
      </div>

      <div class="block">
        <h2>4. Mises à jour de cette Politique</h2>
        <p>Nous pouvons de temps à autre apporter des modifications à cette Politique.</p>
        <p>Lorsque nous effectuons des modifications importantes de cette politique, nous vous en informons par le biais
          d’un email ou bien d’une notification visible depuis votre compte. </p>
      </div>

      <div class="block">
        <h2>5. Comment nous contacter</h2>
        <p>Merci d'avoir lu notre politique de cookies !</p>
        <p>
          Si vous avez des questions concernant cette dernière, vous pouvez nous contacter depuis le formulaire présent
          sur notre page d’accueil ou bien grâce au menu Aide accessible depuis votre compte !</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.title {
  text-align: center;
}

.title h1 {
  font-size: clamp(1.2rem, 2.2vw, 2.5rem);
}

.block {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.block ol,
.block ul {
  padding: 0 2rem;
}

.block li {
  list-style: auto;
  color: var(--color-dark-blue-secondary);
}

.block ul li {
  list-style-type: circle
}

.table {
  border-collapse: collapse;
}

.table thead td {
  padding: 1rem;
}

.table td {
  color: var(--color-dark-blue-secondary);
  padding: 1rem;
  border: 1px black solid;
}
</style>
