import 'jrmc/base-refonte'
import {createSSRApp} from 'vue'
import { createRouter } from 'jrmc/vue/prelogin/router'
import { createPinia } from 'pinia'
import App from 'jrmc/vue/prelogin/App.vue'
import 'jrmc/base-refonte/css/shared.css'
import {createHead, VueHeadMixin} from "@unhead/vue";
import "jrmc/vue/prelogin/utils/form-validation";

export function createApp() {
  const app = createSSRApp(App)
  app.use(createPinia());
  const router = createRouter()
  app.use(router);
  const head = createHead();
  app.use(head);
  app.mixin(VueHeadMixin);

  return { app, router, head }
}
