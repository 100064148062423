<script setup lang="ts">
import Hero from "jrmc/vue/prelogin/components/Home/Hero.vue";
import PresentationSection from "jrmc/vue/prelogin/components/Home/PresentationSection.vue";
import ServicesSection from "jrmc/vue/prelogin/components/Home/ServicesSection.vue";
import ValorSection from "jrmc/vue/prelogin/components/Home/ValorSection.vue";
import { setMetaTags } from "jrmc/vue/prelogin/seo/seo.server";
import { Organisation } from "jrmc/vue/prelogin/seo/homepage";
import { useHead } from "@unhead/vue";
import useAssets from "jrmc/vue/prelogin/composable/useAssets";
import { useRoute } from "vue-router";
import CustomerReviewSection from "jrmc/vue/prelogin/components/CustomerReview/CustomerReviewSection.vue";
import CustomerTypologySection from "jrmc/vue/prelogin/components/Home/CustomerTypologySection.vue";
import AdvantageSection from "jrmc/vue/prelogin/components/Home/AdvantageSection.vue";
import { useFaqData } from "jrmc/vue/prelogin/composable/useFaqData";
import { FaqSpecificPage } from "jrmc/vue/prelogin/enums/faq";
import FaqByPage from "jrmc/vue/prelogin/components/FaqByPage.vue";
import LegalsSection from "jrmc/vue/prelogin/components/Home/LegalsSection.vue";
import Pricing from "jrmc/vue/prelogin/components/Home/Pricing.vue";
import AboutUs from "jrmc/vue/prelogin/components/Home/AboutUs.vue";

const { faq, loading, fetchData } = useFaqData();
const route = useRoute();

useHead({
  script: [
    {
      type: "application/ld+json",
      innerHTML: JSON.stringify(Organisation),
    },
  ],
  link: [
    {
      rel: "preload",
      fetchpriority: "high",
      as: "image",
      href: useAssets("images/Photo_Homme_Avion_Hero.webp"),
      imagesrcset: `${useAssets(
        "images/Photo_Homme_Avion_Hero_scale,w_565.webp"
      )} 550w,
                    ${useAssets(
                      "images/Photo_Homme_Avion_Hero_scale,w_312.webp"
                    )} 300w,
                    ${useAssets(
                      "images/Photo_Homme_Avion_Hero_scale,w_200.webp"
                    )} 280w`,
      imagesizes: "(max-width: 650px) 273px, 550px",
    },
  ],
});
const metaTags = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTags);
await fetchData(FaqSpecificPage.HOME);
</script>

<template>
  <div class="page">
    <Hero />
    <PresentationSection />
    <ServicesSection />
    <CustomerReviewSection />
    <CustomerTypologySection />
    <AdvantageSection />
    <Pricing/>
    <LegalsSection/>
    <AboutUs/>
    <ValorSection />
    <FaqByPage :faqs="faq" :loading="loading" />
  </div>
</template>
