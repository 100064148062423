<script setup lang="ts">
import ValorComponent from "jrmc/vue/prelogin/components/Home/ValorComponent.vue";
const valors = [
  {
    title: "Écoute",
    img: "images/Picto_Valeur_Ecoute.svg",
    paragraph: `La force de notre solution digitale, c’est l’humain. Plus qu’une oreille attentive, notre ADN c’est l’écoute vraiment active !`,
    alt: "Pictogramme d'une oreille pour ecoute",
  },
  {
    title: "Excellence",
    img: "images/Picto_Valeur_Excellence.svg",
    paragraph: `Nous sommes les pionniers de notre marché. Notre référence, c’est l’amélioration continue de la performance !`,
    alt: "Pictogramme d'une medaille pour excellence",
  },
  {
    title: "Engagement",
    img: "images/Picto_Valeur_Engagement.svg",
    paragraph: `Nous créons de l’efficacité, créatrice de liberté. Notre plus grande satisfaction, c’est la sérénité de nos clients et de nos talents !`,
    alt: "pictogramme d'un oiseau pour engagement",
  },
];
</script>
<template>
  <section>
    <div class="container valor-container">
      <h2>JeResilieMonContrat.com, 3 valeurs qui font la différence</h2>
      <p>
        Découvrez ce qui nous tient à coeur : chez JRMC, nos valeurs sont
        le socle de chaque action que nous entreprenons pour vous simplifier la vie
      </p>
      <div class="valor-card-container">
        <ValorComponent v-for="valor in valors" v-bind="valor" />
      </div>
    </div>
  </section>
</template>
<style scoped>
.valor-container > p {
  color: var(--color-orange);
  font-weight: 700;
}
.valor-card-container {
  place-items: start;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem 10rem;
}
@media screen and (max-width: 1024px) {
  .valor-card-container {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
