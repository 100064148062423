<script setup lang='ts'>
import {setMetaTags} from "jrmc/vue/prelogin/seo/seo.server";
import {useRoute} from "vue-router";
import Hero from "jrmc/vue/prelogin/components/Hero.vue";

const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
</script>
<template>
  <Hero :title="'Politique de confidentialité'"/>
  <div class="page">
    <div class="container">
      <div class="title">
        <p>En vigueur à compter du 16 décembre 2024</p>
      </div>
      <div class="block">
        <h2>1. Introduction</h2>
        <p>Merci d'avoir choisi JeResilieMonContrat.com !</p>
        <p>Soucieux de votre confiance, la confidentialité et la sécurité de vos données personnelles sont une priorité
          pour nous.</p>
        <p>Nous souhaitons donc vous expliquer de manière transparente pourquoi nous collectons, stockons, partageons et
          utilisons vos données personnelles.</p>
        <p>Nous vous présenterons également les outils de contrôle et de choix dont vous disposez pour déterminer quand
          et comment vos données personnelles sont partagées.</p>
      </div>
      <div class="block">
        <h2>2. À propos de cette Politique</h2>
        <p>Cette Politique énonce des informations essentielles relatives à l'utilisation de vos données personnelles
          par la Société par actions simplifiée Clic'issimo, qui commercialise le service JeResilieMonContrat.com.
        </p>
        <p>La Politique s'applique à tous les services JeResilieMonContrat.com et services associés et vient compléter
          nos Conditions générales d'utilisation et de vente, qui régissent votre utilisation du Service
          JeResilieMonContrat.com, ainsi que notre politique de cookies, qui précise comment nous utilisons les cookies
          et vous indique comment gérer vos préférences.
        </p>

        <p>Désirant répondre à vos attentes, nous développons et proposons parfois de nouveaux services ou des offres
          supplémentaires satisfaisant vos besoins. Ces derniers sont, sauf indications contraires, soumis à la présente
          politique de confidentialité des données.</p>

        <p>En résumé, le but de cette Politique est de :</p>
        <ol>
          <li>Vous expliquer quelles données personnelles nous collectons, dans quel but nous les recueillons et les
            utilisons, et avec qui nous les partageons.
          </li>
          <li>Détailler la façon dont nous utilisons les données personnelles que vous partagez pour vous faire profiter
            de tous les avantages que peuvent vous apporter les Services JeResilieMonContrat.com.
          </li>
          <li>Définir vos droits et choix relatifs aux données personnelles que nous collectons et traitons, et
            expliquer comment nous protégeons votre vie privée.
          </li>
        </ol>
        <p>Nous espérons que cette Politique vous aidera à comprendre nos engagements pour protéger votre
          confidentialité et votre vie privée.
          Pour nous contacter en cas de questions ou de problèmes, consultez la section 13 « Nous contacter »
          ci-dessous.
          Si vous n'êtes pas d'accord avec le contenu de cette Politique, vous êtes libre de décider de ne pas utiliser
          le Service JeResilieMonContrat.com.</p>
      </div>

      <div class="block">
        <h2>3. Vos droits et préférences sur vos données personnelles </h2>
        <p>En tant qu’individu, vous disposez de droits en ce qui concerne vos données personnelles, notamment par
          application du Règlement général sur la protection des données ou RGPD. </p>
        <p>Cette section vise à établir une liste de vos droits et les expliquer ; à préciser les circonstances dans
          lesquelles ils s’appliquent ; enfin, à vous indiquer comment faire valoir vos droits auprès de la société
          CLIC’ISSIMO.</p>

        <p>En tant qu’individu, vous disposez notamment des droits suivants en ce qui concerne vos données personnelles
          : </p>
        <ul>
          <li><b>Droit d’être informé</b>: le droit d’être informé des données personnelles que nos services traitent à
            votre sujet ainsi que de la manière dont nous les traitons.
            Vous pouvez obtenir ces informations depuis la présente politique de confidentialité, ou bien, si vous avez
            besoin de plus de détails ou précisions, vous pouvez à tout instant contacter notre équipe depuis votre menu
            Aide.
          </li>
          <li><b>Droit d'accès</b> : le droit de demander l'accès aux données personnelles que nous traitons.
            Pour cela, vous pouvez contacter notre équipe via le menu Aide.
          </li>
          <li><b>Droit de rectification</b> : le droit de nous demander de modifier ou de mettre à jour vos données
            personnelles lorsqu'elles sont inexactes ou incomplètes.
            En tant qu'utilisateur, vous pouvez modifier les données vous concernant depuis le menu Mon compte, puis
            Modifier mes coordonnées, ou bien directement en contactant notre équipe via le menu Aide.
          </li>
          <li><b>Droit d'effacement</b> : le droit de nous demander de supprimer définitivement certaines données
            personnelles.<br/>
            Vous pouvez par exemple demander à notre équipe de supprimer les données personnelles :<br/>
            - dont nous n’avons plus besoin au regard des finalités pour lesquelles nous les avons collectées.<br/>
            - que nous traitons sur la base du consentement et pour lesquelles vous souhaitez retirer votre
            consentement.<br/>
            Au contraire, certaines données ne peuvent pas être supprimées. Tel est par exemple le cas si Clic’issimo est
            légalement tenu de conserver ces données ou en a besoin pour exercer ou défendre ses droits.<br/>
            Chaque fois que possible, nous vous mettons à disposition un bouton supprimer qui vous permet d’effacer de
            manière définitive les données personnelles de votre choix.<br/>
            À défaut de la présence d’un bouton supprimer, vous pouvez transmettre votre demande de suppression à notre
            équipe via le menu Aide.
          </li>
          <li>
            <b>Droit de restriction</b> : le droit de nous demander d'arrêter le traitement de tout ou partie de vos
            données personnelles.<br/>
            Une telle faculté vous est notamment ouverte si nos services procèdent à un traitement illicite de vos
            données personnelles.<br/>
            Pour ce faire, vous pouvez exercer votre droit à restriction en contactant notre équipe depuis le menu Aide.
          </li>
          <li>
            <b>Droit d’opposition</b> : cette catégorie inclut<br/>
            - le droit de refuser à tout moment le traitement de vos données personnelles en précisant les raisons qui
            tiennent à votre situation particulière<br/>
            - le droit de refuser le traitement de vos données personnelles à des fins de marketing direct.<br/>

            Pour ce faire, vous pouvez exercer votre droit à opposition en contactant notre équipe depuis le menu Aide.
          </li>
          <li>
            <b>Droit à la portabilité des données</b> : le droit de demander une copie de vos données personnelles au
            format électronique et le droit de transmettre ces données personnelles pour une utilisation par un service
            tiers.<br/>
            Pour ce faire, vous pouvez effectuer une demande en ce sens en contactant notre équipe depuis le menu Aide.
          </li>
          <li>
            <b>Droit de ne pas être soumis à la prise de décision automatisée</b> : le droit de ne pas être soumis à une
            décision basée uniquement sur la prise de décision automatisée (autrement dit une prise de décision qui
            n’implique pas d’êtres humains), y compris le profilage, dans le cas où la décision aurait un effet
            juridique sur vous ou produirait un effet significatif similaire.
          </li>
          <li>
            <b>Retrait de consentement</b> : vous pouvez choisir de retirer votre consentement à la collecte ou bien à
            l’utilisation de vos données personnelles chaque fois que Clic’issimo traite vos données personnelles sur la base
            légale du consentement.<br/>
            Pour ce faire, contactez notre équipe depuis le menu Aide ou bien, cliquez sur le lien de désinscription
            quand votre retrait de consentement vise un message de marketing électronique.
          </li>
          <li>
            <b>Droit de déposer une plainte </b>: pour toute question ou interrogation, vous pouvez contacter la CNIL
            depuis son site internet
          </li>
        </ul>
        <p>Pour obtenir plus de détails sur les droits dont vous jouissez, notre responsable de la protection des
          données est à votre écoute (coordonnées mentionnées en section 13 « Nous contacter »). Ce dernier vous
          répondra dans un délai raisonnable, après vérification de votre identité.</p>
      </div>

      <div class="block">
        <h2>4. Comment collectons-nous vos données personnelles ?</h2>
        <p>Nous collectons vos données personnelles :</p>
        <ol>
          <li><b>Lorsque vous créez un compte sur le Service JeResilieMonContrat.com</b> : lorsque vous vous inscrivez
            au Service JeResilieMonContrat.com, nous collectons certaines données personnelles, comme votre adresse
            e-mail et vos coordonnées, pour vous permettre d'utiliser le Service JeResilieMonContrat.com.
          </li>
          <li><b>À chacune des utilisations du Service JeResilieMonContrat.com</b> : lorsque vous créez une résiliation
            d'un contrat d'assurance sur le Service JeResilieMonContrat.com ou tout autre courrier, nous collectons des
            données personnelles pour la bonne exécution du service demandé comme les informations sur vos souscripteurs
            ou les informations concernant leurs contrats d'assurance.
          </li>
          <li><b>Données personnelles collectées dans le but de vous fournir des fonctions/fonctionnalités
            supplémentaires</b> : vous pouvez parfois nous fournir des données personnelles complémentaires (par exemple
            votre affiliation) pour profiter de fonctions ou fonctionnalités supplémentaires.
          </li>
        </ol>
        <p>Nous utilisons également des informations agrégées et anonymes dans le but notamment de tester nos systèmes
          informatiques, d'effectuer des recherches, d'analyser des données, de créer des modèles marketing et
          promotionnels, d'améliorer le Service JeResilieMonContrat.com et de développer de nouvelles fonctions et
          fonctionnalités au sein du Service JeResilieMonContrat.com.</p>
      </div>

      <div class="block">
        <h2>5. Quelles données personnelles vous concernant collectons-nous ?</h2>
        <p>Nous veillons à collecter uniquement les données qui sont nécessaires à la réalisation directe ou indirecte
          des services pour lesquels vous nous avez sollicités. </p>
        <p>Pour votre parfaite information, nous avons défini dans les tableaux ci-dessous les catégories de données
          personnelles que nous recueillons et utilisons :</p>
        <b>Données recueillies lorsque vous vous inscrivez sur la plateforme JeResilieMonContrat.com ou lorsque vous
          mettez à jour votre compte</b>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th>Catégories de données personnelles</th>
            <th>Description de la catégorie</th>
            <th>Durée de conservation</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><strong>Données d'utilisateur</strong></td>
            <td>
              Ce sont les données personnelles que vous avez fournies ou que nous avons recueillies pour vous permettre
              de vous inscrire et d'utiliser le Service JeResilieMonContrat.com.<br/><br/>
              Ce sont également les données que vous avez renseignées dans le cadre d’une modification de vos
              coordonnées en tant qu’utilisateur de nos services.<br/><br/>
              Elles comprennent vos données d’identification et coordonnées de contact :
              <ul>
                <li>adresse e-mail</li>
                <li>votre nom</li>
                <li>votre prénom</li>
                <li>votre adresse postale</li>
                <li>votre numéro de téléphone</li>
                <li>votre nom commercial</li>
                <li>votre numéro ORIAS</li>
                <li>le réseau auquel vous êtes rattaché</li>
              </ul>

              <br/>
              Certaines données personnelles requises sont indispensables pour créer votre compte.<br/><br/>
              Vous avez aussi la possibilité d’ajouter des données personnelles à posteriori afin de personnaliser
              encore davantage votre compte.<br/><br/>

              Certaines de ces informations apparaissent également sur les preuves des envois électroniques dans le cas
              où vous êtes l'émetteur des courriers :
              <ul>
                <li>votre nom commercial</li>
                <li>votre nom</li>
                <li>votre prénom</li>
                <li>votre adresse postale</li>
              </ul>
              <br/>
              Également, ces données sont indispensables pour la facturation ainsi que pour la bonne tenue de notre
              comptabilité générale.
            </td>
            <td>
              Sans limite de durée, tant que vous êtes un utilisateur du service JeResilieMonContrat.com et que vous
              n’avez pas exprimé votre droit à suppression.
              <br/><br/>
              Concernant les preuves des envois électroniques sur lesquelles figurent vos coordonnées en votre qualité
              d’émetteur, celles-ci sont conservées dans la limite de 7 ans, sauf demande contraire de votre part.
            </td>
          </tr>
          </tbody>
        </table>
        <b>Données personnelles collectées par le biais de votre utilisation du Service JeResilieMonContrat.com</b>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th bgcolor="#337ab7">Catégories de données personnelles</th>
            <th bgcolor="#337ab7">Description de la catégorie</th>
            <th bgcolor="#337ab7">Durée de conservation</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><b>Informations pour une résiliation ou une substitution de contrats d’assurance</b></td>
            <td>
              Il s'agit des informations que vous renseignez sur le ou les souscripteur(s) du contrat d’assurance pour
              permettre de générer une lettre de résiliation :
              <br/><br/>
              <ul>
                <li>Coordonnées complètes :
                  <ul>
                    <li>Civilité</li>
                    <li>Nom d'usage / Nom de naissance / Prénom</li>
                    <li>Adresse postale complète</li>
                    <li>Société / Numéro SIRET (facultatif)</li>
                    <li>Adresse mail</li>
                  </ul>
                </li>
                <li>
                  Informations sur les contrats à résilier
                  <ul>
                    <li>Type de contrat</li>
                    <li>Date d'échéance du contrat à résilier (facultative dans certains cas)</li>
                    <li>Référence du contrat à résilier</li>
                    <li>Ancien assureur (facultatif dans certains cas)</li>
                    <li>Date de signature de l’offre de prêt dans le cadre d’une assurance emprunteur</li>
                  </ul>
                </li>
                <li>
                  Les pièces que vous choisissez de joindre à votre courrier peuvent comprendre des données
                  personnelles, par exemple dans le titre donné au fichier
                </li>
              </ul>

            </td>
            <td>
              Les données formant le contenu de votre courrier sont conservées dans la limite de 7 ans, sauf demande
              contraire de votre part.
              <br/><br/>
              Les preuves d'envoi de vos courriers électroniques sont conservées quant à elles, pendant 7 ans.
            </td>
          </tr>
          <tr>
            <td><b>Informations pour un courrier papier</b></td>
            <td>Il s'agit des informations et documents que vous renseignez lors de la constitution d'un courrier papier
              et que vous pouvez retrouver sur les justificatifs d'envoi postaux.
              <ul>
                <li>
                  Émetteur du courrier (uniquement lorsque le mode d’envoi défini requiert ces informations) :
                  <ul>
                    <li>Nom complet de l’émetteur du pli</li>
                    <li>Adresse postale complète</li>
                    <li>Code postal / Ville</li>
                  </ul>
                </li>
                <li>
                  Destinataire du courrier :
                  <ul>
                    <li>Nom complet</li>
                    <li>Adresse postale complète</li>
                    <li>Code postal / Ville</li>
                  </ul>
                </li>
                <li>
                  Possibles données personnelles dans les pièces jointes transmises (exemple du titre du fichier par
                  exemple)
                </li>
              </ul>

            </td>
            <td>
              Les documents associés à la lettre, formant le contenu du courrier, sont supprimés au bout de 60 jours.
              <br/><br/>
              Les données associées à la lettre telles que les coordonnées de l’émetteur et du destinataire sont
              conservées 5 ans.
              <br/><br/>
              Les preuves d'envoi de vos courriers papier sont conservées pendant 5 ans également.
            </td>
          </tr>
          <tr>
            <td><b>Informations supplémentaires vous concernant</b></td>
            <td>
              Il s'agit des informations supplémentaires nécessaires lors de l'envoi de la lettre en votre nom. A
              défaut, vous pouvez être inscrit sur le site mais non utilisateur des services et dans ce cas, ce document
              ne sera pas requis.
              <ul>
                <li>Document attestant de votre identité professionnelle (de type attestation ORIAS dans le cadre d’une
                  activité d’intermédiaire en assurance ou bien statuts de la société ou son kbis).
                </li>
              </ul>
              <br/>
              Ce document s’inscrit dans une procédure d’authentification, réalisée en accord avec la réglementation
              européenne qui encadre les envois de lettres recommandées électroniques et constitue un préalable
              obligatoire à tout envoi de courriers électroniques

            </td>
            <td>
              Les documents attestant l’identité professionnelle sont, quant à eux, conservés sans limite de durée, tant
              que vous êtes un utilisateur du service JeResilieMonContrat.com et que vous n’avez pas exprimé votre
              droit à suppression.
            </td>
          </tr>
          <tr>
            <td><b>Données relatives aux demandes d'aide et aux réclamations ainsi qu’à toutes vos interactions
              avec nos services </b></td>
            <td>
              Chaque fois que vous sollicitez l’aide de notre équipe (via le site internet, par email ou par téléphone)
              ou bien émettez une suggestion/commentaire via le site ou les réseaux sociaux ou toutes autres réclamations,
              nous stockons les informations suivantes :
              <ul>
                <li>Votre demande</li>
                <li>Pièces jointes éventuelles</li>
              </ul>
            </td>
            <td>
              Conservation dans la limite de durée du compte auquel ces demandes sont rattachées.
            </td>
          </tr>
          <tr>
            <td><b>Données relatives aux prises de rendez-vous</b></td>
            <td>
              Vous avez la possibilité d'effectuer un rendez-vous avec notre équipe pour présentation du service.
              <br/><br/>
              Nous collectons certaines informations à ce sujet, dans le but de pouvoir, à votre demande, vous
              recontacter :
              <ul>
                <li>Votre adresse mail</li>
                <li>Votre numéro de téléphone</li>
              </ul>
            </td>
            <td>
              Conservation dans la limite de 2 ans
            </td>
          </tr>
          <tr>
            <td><b>Données d’enquête et d’études </b></td>
            <td>
              Lorsque vous répondez à une enquête satisfaction ou répondez à une étude utilisateur, nous collectons et
              utilisons les données personnelles que vous communiquez à des fins d’analyse et d'amélioration de nos
              services
            </td>
            <td>
              Conservation dans la limite de 2 ans
            </td>
          </tr>
          <tr>
            <td>
              <b>Données d’utilisation</b>
            </td>
            <td>
              Toutes les données liées à la connexion et à l’utilisation de nos services : adresse IP, type de
              navigateur et sa version, système d'exploitation et sa version, logs, historique de navigation
            </td>
            <td>
              Conservation dans la limite de 1 an
            </td>
          </tr>
          <tr>
            <td>
              <b>Données relatives à l’exercice de droits RGPD</b>
            </td>
            <td>
              Toutes les données récoltées dans le cadre d’une demande d’exercice de droit RGPD :
              <ul>
                <li>Nom, Prénom</li>
                <li>Coordonnées postales</li>
                <li>Coordonnées téléphoniques</li>
                <li>Emails</li>
                <li>Pièces d’identité</li>
              </ul>
            </td>
            <td>
              5 ans pour les données relatives au traitement de votre demande, 1 an pour le document d’identité
            </td>
          </tr>
          <tr>
            <td>
              <b>Données comptables et facturation</b>
            </td>
            <td>
              Toutes les factures et autres informations permettant à notre société de tenir et suivre sa comptabilité
              conformément à ses obligations légales
            </td>
            <td>
              Conservation pendant l’exercice en cours, augmenté de dix (10) ans à compter de la clôture
            </td>
          </tr>
          <tr>
            <td>
              <b>Données statistiques</b>
            </td>
            <td>
              Génération de statistiques d’utilisation anonymisées
            </td>
            <td>
              Conservation dans la limite de durée du compte sur lequel ces statistiques se basent
            </td>
          </tr>
          </tbody>
        </table>


        <b>Données mentionnées ci dessus que nous pouvons également recueillir de la part de nos partenaires</b>

        <table class="table table-bordered">
          <thead>
          <tr>
            <th bgcolor="#337ab7">Catégories de données personnelles</th>
            <th bgcolor="#337ab7">Description de la catégorie</th>
            <th bgcolor="#337ab7">Durée de conservation</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><b>Données d'utilisateur</b></td>
            <td>
              Si vous vous inscrivez ou connectez à la plateforme JeResilieMonContrat.com en passant par un autre
              service partenaire, il est possible que, dans le cadre du partenariat qui nous unit, ce dernier nous
              communique vos informations. Ces informations nous permettront de créer votre compte dans notre système
              pour vous permettre ensuite d’utiliser nos services.
            </td>
            <td>
              Sans limite de durée, tant que vous êtes un utilisateur du service JeResilieMonContrat.com et que vous
              n’avez pas exprimé votre droit à suppression.
            </td>
          </tr>
          <tr>
            <td>
              <b>Données de paiement</b>
            </td>
            <td>
              Vous avez la possibilité d'effectuer des paiements par carte bancaire depuis la plateforme bancaire
              Sherlock. Si vous choisissez de payer par l’intermédiaire de Sherlock, votre paiement alimente ensuite le
              crédit de votre compte JRMC pour vous permettre de profiter des différents services que nous proposons.
              <br/><br/>
              En aucun cas, et à aucun moment, nous n'avons connaissance ni ne conservons les numéros complets et les
              codes de vos moyens de paiement.
              <br/>
              Le paiement étant effectué directement depuis la plateforme Sherlock, seule celle-ci a accès (et est
              habilitée pour) aux informations complètes de vos moyens de paiement.
              <br/>
              <br/>
              Nous recevons néanmoins certaines informations afin de pouvoir traiter votre paiement et créditer votre
              compte, informations que nous pouvons ensuite conserver à des fins de suivi et de facturation, et pour les
              besoins de notre comptabilité générale :
              <ul>
                <li>Montant de la transaction</li>
                <li>Date et heure de la transaction</li>
                <li>Métadonnées relatives à la transaction</li>
                <li>Le type de carte de crédit ou de débit, la date d'expiration et certains chiffres du numéro de votre
                  carte
                </li>
                <li>Statut du paiement</li>
              </ul>
            </td>
            <td>
              Conservation dans la limite de 15 ans
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="block">
        <h2>6. Comment utilisons-nous vos données personnelles ?</h2>
        <p>Nous avons indiqué dans le tableau ci-dessous les raisons pour lesquelles nous traitons vos données
          personnelles, les normes juridiques associées sur lesquelles nous nous basons pour traiter légalement vos
          données personnelles et les catégories de données personnelles concernées par le traitement (identifiées dans
          la section 5 « Quelles données personnelles vous concernant collectons-nous ? ») :
        </p>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th bgcolor="#337ab7">Description de la raison pour laquelle JeResilieMonContrat.com traite vos données
              personnelles (« objectif du traitement »)
            </th>
            <th bgcolor="#337ab7">Base juridique de l'objectif du traitement</th>
            <th bgcolor="#337ab7">Catégories de données personnelles utilisées par JeResilieMonContrat.com pour
              l'objectif du traitement
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              Enregistrement de votre inscription pour vous fournir les services attendus conformément au contrat conclu
              <br/>
              Par exemple, lorsque nous utilisons vos données pour vous créer un compte, pour ajouter des paramétrages à
              ce compte, modifier les informations du compte, ajouter des profils d’utilisation
            </td>
            <td>
              <ul>
                <li>Exécution d'un contrat</li>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d'utilisateur</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Procéder à la génération ainsi qu’à l’envoi de votre lettre de résiliation
            </td>
            <td>
              <ul>
                <li>Exécution d'un contrat</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d'utilisateur</li>
                <li>Informations pour une résiliation ou une substitution de contrats d’assurance</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Envoyer votre courrier papier
            </td>
            <td>
              <ul>
                <li>Exécution d'un contrat</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Informations pour un courrier papier</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Créditer votre compte utilisateur
            </td>
            <td>
              <ul>
                <li>Exécution d'un contrat</li>
                <li>Respect des obligations légales</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d'utilisateur</li>
                <li>Données de paiement</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Diagnostiquer et résoudre des soucis avec le service JRMC
            </td>
            <td>
              <ul>
                <li>Exécution d'un contrat</li>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d'utilisateur</li>
                <li>Données d’utilisation</li>
                <li>Données de paiement</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Procéder à une recherche ou vérification par notre équipe pour vous apporter une réponse satisfaisante
            </td>
            <td>
              <ul>
                <li>Exécution d'un contrat</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d'utilisateur</li>
                <li>Données de paiement</li>
                <li>Données d’utilisation</li>
                <li>Informations pour une résiliation ou une substitution de contrats d’assurance</li>
                <li>Informations pour l’envoi d’un courrier papier</li>
                <li>Informations supplémentaires vous concernant</li>
                <li>Et toutes autres informations</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Génération des justificatifs comptables et éléments de facturation, suivie et tenue de la comptabilité
            </td>
            <td>
              <ul>
                <li>Exécution d'un contrat</li>
                <li>Respect des obligations légales</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d'utilisateur</li>
                <li>Données de paiement</li>
                <li>Données comptables et facturation</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Apporter une réponse satisfaisante à toutes vos interrogations
            </td>
            <td>
              <ul>
                <li>Exécution d'un contrat</li>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données relatives aux demandes d'aide et aux réclamations ainsi qu’à toutes vos interactions avec nos services </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Prise de contact avec vous chaque fois que vous en faites la demande depuis le formulaire de contact
            </td>
            <td>
              <ul>
                <li>Consentement</li>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données relatives aux prises de rendez-vous</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Gestion de la relation commerciale avec le client, en ce compris les réponses aux demandes de contact qui nous sont adressées
              <br/>
              Gestion des adhésions aux newsletters
            </td>
            <td>
              <ul>
                <li>Consentement dans le cas des newsletters</li>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d’utilisateur</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Pour évaluer et développer de nouvelles fonctionnalités ou améliorer notre service JRMC
            </td>
            <td>
              <ul>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d’utilisateur</li>
                <li>Données d’utilisation</li>
                <li>Données d’enquête et d’étude</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              À des fins de marketing et de promotion, et pour mener des actions commerciales, lorsque la loi nous oblige à recueillir votre consentement
              <br/>
              Par exemple lorsque nous utilisons des cookies pour comprendre vos intérêts
            </td>
            <td>
              <ul>
                <li>Consentement</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d’utilisateur</li>
                <li>Données d’utilisation</li>
                <li>Données d’enquête et d’étude</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              À d’autres fins de marketing et de promotion, et pour mener des actions commerciales, lorsque la loi ne nous oblige pas à recueillir votre consentement
            </td>
            <td>
              <ul>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d’utilisateur</li>
                <li>Données d’utilisation</li>
                <li>Données d’enquête et d’étude</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Pour nous conformer à une demande des forces de l’ordre, des tribunaux ou d’autres autorités compétentes
            </td>
            <td>
              <ul>
                <li>Obligation légale </li>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d'utilisateur</li>
                <li>Données de paiement</li>
                <li>Données d’utilisation</li>
                <li>Données d’enquête et d’étude </li>
                <li>Informations pour une résiliation ou une substitution de contrats d’assurance</li>
                <li>Informations pour l’envoi d’un courrier papier</li>
                <li>Et toutes autres informations pouvant s’avérer pertinentes </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Pour respecter les obligations contractuelles avec des tiers
              <br/>
              Par exemple, lorsque nous fournissons des statistiques agrégées sur les types de contrats concernés par les résiliations à nos partenaires
            </td>
            <td>
              Intérêts légitimes<br/>
              En ce compris maintenir nos relations afin de pouvoir le fournir le service JRMC
            </td>
            <td>
              <ul>
                <li>Données statistiques</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Pour établir, exercer ou défendre des droits
              <br/>
              Par exemple, si nous sommes impliqués dans un litige et si nous devons fournir des informations à nos avocats concernant l’affaire
            </td>
            <td>
              Intérêts légitimes
              en ce compris :
              <ul>
                <li>Obtenir des conseils juridiques</li>
                <li>Nous protéger ou protéger nos utilisateurs ou d’autres personnes dans le cadre de procédures juridiques</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d'utilisateur</li>
                <li>Données de paiement</li>
                <li>Données d’utilisation</li>
                <li>Données d’enquête et d’étude </li>
                <li>Informations pour une résiliation ou une substitution de contrats d’assurance</li>
                <li>Informations pour l’envoi d’un courrier papier</li>
                <li>Et toutes autres informations pouvant s’avérer pertinentes </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Pour réaliser des opérations de reporting et des prévisions
              <br/>
              Par exemple, lorsque nous analysons des statistiques anonymisées afin d’étudier les opportunités  d’un nouveau service
            </td>
            <td>
              <ul>
                <li>Intérêt légitime</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d’utilisateur</li>
                <li>Données d’utilisation </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Pour sécuriser notre plateforme et prévenir les cyberattaques
            </td>
            <td>
              <ul>
                <li>Intérêt légitime </li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d’utilisation </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Pour réaliser des études et des enquêtes
              <br/>
              Par exemple lorsque nous vous contactons afin de vous demander votre avis
            </td>
            <td>
              <ul>
                <li>Intérêts légitimes</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données d’utilisateur</li>
                <li>Données d’enquêtes et d’études </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              Traiter vos demandes d’exercices de droits dans le cadre du RGPD
            </td>
            <td>
              <ul>
                <li>Obligation légale</li>
              </ul>
            </td>
            <td>
              <ul>
                <li>Données dans le cadre de l’exercice de droits RGPD</li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="block">
        <h2>7. Partage de vos données personnelles</h2>
        <p>Les données personnelles suivantes sont partagées avec nos prestataires indispensables à la bonne réalisation des services proposés par JeResilieMonContrat.com et contribuant également à votre satisfaction.</p>
        <p>
          La liste ci-dessous ne vise pas les personnes avec qui vous pouvez choisir de partager vos données personnelles. Par exemple, lors de l’envoi d’un courrier, vous pouvez choisir de notifier un tiers de cet envoi et son adresse email sera alors nécessaire.
        </p>
        <table class="table table-bordered">
          <thead>
          <tr>
            <th bgcolor="#337ab7">Catégories de destinataires</th>
            <th bgcolor="#337ab7">Raison du partage</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><strong>Opérateur postal numérique qualifié eIDAS</strong></td>
            <td>
              Nous avons choisi un opérateur postal numérique qualifié eIDAS pour envoyer vos
              courriers recommandés électroniques.<br>
              Nous transmettons à ce prestataire toutes les informations dont il a besoin pour être en
              mesure de réaliser l’acheminement de vos courriers électroniques à leur destinataire.
            </td>
          </tr>
          <tr>
            <td><strong>Prestataire d'impression, de mise sous pli et d'affranchissement de courriers papier (situé en France)</strong></td>
            <td>
              Nous envoyons à ce prestataire toutes les informations utiles permettant d'imprimer le courrier papier, de le mettre sous pli, de l'affranchir puis de le transmettre à l’opérateur postal pour acheminement.
            </td>
          </tr>
          <tr>
            <td><strong>Prestataire de signature électronique qualifié eIDAS</strong></td>
            <td>
              Nous envoyons à ce prestataire toutes les informations utiles permettant à votre client, ou vous permettant, de signer de manière électronique des documents.
            </td>
          </tr>
          <tr>
            <td><strong>Nos hébergeurs</strong></td>
            <td>
              Indispensables au bon fonctionnement du site et à la mise en place d’un système de sauvegardes en cas de besoin.  Le stockage de vos données s’effectue sur des serveurs installés en France sur lesquels nous avons un accès exclusif.
            </td>
          </tr>
          <tr>
            <td>
              <b>Plateforme de paiement</b>
            </td>
            <td>
              Afin qu’ils puissent traiter vos paiements et lutter contre la fraude
            </td>
          </tr>
          <tr>
            <td>
              <b>Partenaires marketing</b>
            </td>
            <td>
              Pour promouvoir nos services JRMC et nous aider à mesurer l’efficacité de nos actions
            </td>
          </tr>
          <tr>
            <td>
              <b>Autres sociétés du groupe</b>
            </td>
            <td>
              Afin de pouvoir mener à bien nos missions quotidiennes et de pouvoir vous fournir tous les services dont vous avez besoin
              <br/>
              Pour évaluer nos résultats et nous permettre de nous améliorer
            </td>
          </tr>
          <tr>
            <td>
              <b>Autorités chargées de l’application des lois et autres autorités, ou autres parties au litige</b>
            </td>
            <td>
              Chaque fois que nous estimons qu’une réponse est nécessaire ou bien lorsqu'une réponse est obligatoire
            </td>
          </tr>
          <tr>
            <td>
              <b>Nos conseils et partenaires divers</b>
            </td>
            <td>
              Dès lors qu’une information peut être pertinente pour leur mission de conseil ou d’accompagnement et que cela intervient dans le cadre d’une relation contractuelle
            </td>
          </tr>
          <tr>
            <td>
              <b>Et toutes les personnes à qui vous avez choisi, en tant qu'utilisateur de nos services, de notifier l’envoi de votre courrier</b>
            </td>
            <td>
              Il peut s’agir du souscripteur du contrat, son ayant droit, bénéficiaire du contrat d’assurance, gestionnaire du contrat à résilier etc ...
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="block">
        <h2>8. Conservation et suppression des données</h2>
        <p>Nous conservons vos données personnelles uniquement aussi longtemps que nécessaire pour vous fournir le Service JeResilieMonContrat.com et à des fins légitimes et commerciales essentielles, telles que le maintien d'un Service JeResilieMonContrat.com performant ou bien encore la communication sur de nouveaux services en réponse à vos besoins, la conformité avec nos obligations légales et la résolution des différends.</p>
        <p>Certaines de vos données personnelles sont conservées tant que vous êtes un utilisateur du Service JeResilieMonContrat.com. </p>
        <p>Sur votre demande, nous supprimerons ou rendrons anonymes vos données personnelles afin que vous ne puissiez plus être identifié, à moins que la loi ne nous autorise ou nous contraigne à conserver certaines données personnelles, notamment dans les situations suivantes :</p>

        <ul>
          <li>S'il existe un problème non résolu concernant votre compte, comme un impayé ou une réclamation ou un litige non résolu, nous conserverons les données personnelles nécessaires jusqu'à ce que ledit problème soit résolu.
          </li>
          <li>Si nous sommes tenus de conserver les données personnelles du fait d'obligations juridiques, fiscales, d'audit et de comptabilité, nous conserverons les données personnelles nécessaires pendant la période exigée par la loi applicable.
          </li>
          <li>Si ces données sont requises pour nos intérêts commerciaux légitimes, tels que la prévention des fraudes ou la sécurité de nos utilisateurs.
          </li>
        </ul>

        <p>D’autres données seront automatiquement supprimées à l’expiration d’un certain délai. </p>
      </div>

      <div class="block">
        <h2>9. Transfert dans d'autres pays</h2>
        <p>Les données personnelles traitées par la société CLIC’ISSIMO (services JeResilieMonContrat.com) sont hébergées au sein de l’Union Européenne et aucun transfert n’intervient en dehors de l’Union Européenne.</p>
      </div>

      <div class="block">
        <h2>10. Sécurité des données personnelles</h2>
        <p>Nous nous engageons à protéger les données personnelles de nos utilisateurs. Pour ce faire, nous mettons en œuvre des mesures techniques et organisationnelles appropriées afin de protéger la sécurité de vos données personnelles. Cependant, veuillez noter qu'aucun système n'est totalement sûr.</p>
        <p>Nous avons mis en place diverses politiques, y compris des politiques de pseudonyme, de chiffrement, d'accès et de conservation visant à empêcher tout accès non autorisé et toute conservation inutile des données personnelles dans nos systèmes.</p>
        <p>Votre mot de passe protège votre compte utilisateur, nous vous encourageons donc à utiliser un mot de passe sûr et unique, à limiter l'accès à votre ordinateur et votre navigateur et à vous déconnecter après avoir utilisé le Service JeResilieMonContrat.com.</p>
      </div>

      <div class="block">
        <h2>11. Modifications apportées à cette Politique de confidentialité</h2>
        <p>Nous pouvons être amenés à apporter des modifications à la présente Politique.</p>
        <p>
          En cas de modification de cette Politique, nous vous en informerons par le biais de notifications adaptées, par exemple, en affichant une notification depuis votre compte JRMC ou bien en vous envoyant un e-mail. Nous pouvons vous aviser de ces modifications à l'avance.
        </p>
        <p>Assurez-vous de lire attentivement ces mentions.</p>
      </div>

      <div class="block">
        <h2>12. Nous contacter</h2>
        <p>Merci d'avoir lu notre Politique de confidentialité !</p>
        <p>
          Si vous avez des questions sur cette Politique, contactez notre responsable de la protection des
          données :
        </p>
        <ul>
          <li>
            Par email à l’adresse suivante: <a
            href="mailto:dpo@jeresiliemoncontrat.com">dpo@jeresiliemoncontrat.com</a>
          </li>
          <li>
            par voie postale en écrivant à :<br>
            <div style="margin-left: 40px">
              SAS CLIC’ISSIMO<br>
              7 CHEMIN DE LA TRIERE<br>
              38240 MEYLAN
            </div>
          </li>
        </ul>
        <p>Nous espérons que vous apprécierez JeResilieMonContrat.com !</p>
      </div>
    </div>
  </div>
</template>
<style scoped>
.title {
  text-align: center;
}

.title h1 {
  font-size: clamp(1.2rem, 2.2vw, 2.5rem);
}

.block {
  margin: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.block ol,
.block ul {
  padding: 0 2rem;
}

.block li {
  list-style: auto;
  color: var(--color-dark-blue-secondary);
}

.block ul li {
  list-style-type: circle
}

table {
  border-collapse: separate;
  border: solid var(--color-gray-border) 1px;
  border-radius: var(--border-radius);
}

.table thead td {
  padding: 1rem;
}

.table td, .table th {
  color: var(--color-dark-blue-secondary);
  padding: 1rem;
  border-left: solid var(--color-gray-border) 1px;
  border-top: solid var(--color-gray-border) 1px;
}

th {
  background-color: var(--color-light-blue-primary);
  border-top: none;
}

td:first-child, th:first-child {
  border-left: none;
}

th:first-child { border-radius: var(--border-radius) 0 0 0; }
th:last-child { border-radius: 0 var(--border-radius) 0 0; }
tr:last-child td:first-child { border-radius: 0 0 0 var(--border-radius); }
tr:last-child td:last-child { border-radius: 0 0 var(--border-radius) 0; }
</style>
