<script setup lang="ts">
import Hero from 'jrmc/vue/prelogin/components/Hero.vue'
import AboutUsSection from 'jrmc/vue/prelogin/components/About/AboutUsSection.vue'
import JrmcSection from 'jrmc/vue/prelogin/components/About/JrmcSection.vue'
import PartnersComponent from "jrmc/vue/prelogin/components/PartnersComponent.vue";
import TimeLineSection from "jrmc/vue/prelogin/components/About/TimeLineSection.vue";
import { Routes } from "jrmc/vue/prelogin/enums/routes";
import { setMetaTags } from 'jrmc/vue/prelogin/seo/seo.server';
import {useRoute} from "vue-router";
import OurMissions from "jrmc/vue/prelogin/components/About/OurMissions.vue";
import CustomerReviewSection from "jrmc/vue/prelogin/components/CustomerReview/CustomerReviewSection.vue";
import ReinsuranceAboutUsSection from 'jrmc/vue/prelogin/components/About/ReinsuranceAboutUsSection.vue';

const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};

setMetaTags(metaTag);
</script>

<template>
  <div class="page">
    <Hero :title="'Qui sommes-nous ?'" :img="'images/header_about.webp'" :left="true" />
    <OurMissions/>
    <AboutUsSection/>
    <TimeLineSection/>
    <ReinsuranceAboutUsSection/>
    <JrmcSection/>
    <CustomerReviewSection/>
    <section>
      <PartnersComponent
        :title="'Plus de 50 partenaires maîtrisent l’art de la résiliation'"
        :subtitle="'Des leaders du secteur de l\'assurance choisissent notre expertise pour optimiser et accélérer leurs procédures de résiliation'"
      />
      <RouterLink :to="{name: Routes.INSCRIPTION}" class="btn btn-orange cta">S'inscrire</RouterLink>
    </section>
  </div>
</template>

<style scoped>
.cta{
    position: relative;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
}
</style>
