<script setup lang="ts"></script>

<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="10" width="20" height="3" fill="#F39422" />
    <rect x="10" y="1" width="3" height="20" fill="#F39422" />
  </svg>
</template>
