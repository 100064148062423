<script setup lang="ts">
import FormImage from "jrmc/vue/prelogin/components/FormImage.vue";
import ConnexionForm from "jrmc/vue/prelogin/components/Connexion/ConnexionForm.vue";

const imageFormData = {
  imageUrl: "woman_connexion.webp",
  left: true,
  title: "Nous sommes ravis de vous revoir !",
};
</script>

<template>
  <div class="connexion-section">
    <div class="container connexion-container">
      <FormImage v-bind="imageFormData" />
      <ConnexionForm />
    </div>
  </div>
</template>

<style scoped>
.connexion-section {
  display: flex;
  background-image: url("../../assets/images/waves_pattern.svg");
  background-repeat: no-repeat;
  background-position: bottom left;
  background-color: var(--color-light-blue-primary);
  background-size: min(100vh,200%);
  padding: 2rem 0;
  min-height: 100vh;
}
.connexion-container {
  display: flex;
  gap: 3rem;
  margin-top: max(7%,6rem);
}

@media screen and (max-width: 1024px) {
  .glassmorph-container {
    display: none;
  }
  .connexion-container {
    justify-content: center;
  }
}
</style>
