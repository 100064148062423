<script setup lang="ts">
import Hero from "jrmc/vue/prelogin/components/Hero.vue";
import ServiceCardSection from "jrmc/vue/prelogin/components/Service/ServiceCardSection.vue";
import GenerationStepSection from "jrmc/vue/prelogin/components/Service/GenerationStepSection.vue";
import ServiceExplanation from "jrmc/vue/prelogin/components/Service/ServiceExplanation.vue";
import AdvantageSection from "jrmc/vue/prelogin/components/Service/AdvantageSection.vue";
import FaqByPage from "jrmc/vue/prelogin/components/FaqByPage.vue";
import { ids } from "jrmc/vue/prelogin/enums/service";
import { FaqSpecificPage } from "jrmc/vue/prelogin/enums/faq";
import { setMetaTags } from "jrmc/vue/prelogin/seo/seo.server";
import { useRoute } from "vue-router";
import { useFaqData } from "jrmc/vue/prelogin/composable/useFaqData";
import Api from "jrmc/vue/prelogin/components/Service/Api.vue";
import ServiceExplanationCancellation from "jrmc/vue/prelogin/components/Service/ServiceExplanationCancellation.vue";
import ServiceExplanationInssurance from "jrmc/vue/prelogin/components/Service/ServiceExplanationInssurance.vue";
import ServiceExplanationLetterPapper from "jrmc/vue/prelogin/components/Service/ServiceExplanationLetterPapper.vue";
const { faq, loading, fetchData } = useFaqData();
const route = useRoute();

const servicesExplanationSections = [
  {
    title: "Résiliations de contrats d’assurance",
    img: "Photo_Resiliation_Contrat",
    btn: "Envoyer ma lettre de résiliation",
    left: true,
    id: ids.RESILIATION,
    component:ServiceExplanationCancellation
  },
  {
    title: "Changement d’assurance emprunteur",
    img: "Photo_Assurance_Emprunteur",
    btn: "Envoyer ma lettre de substitution",
    left: false,
    id: ids.ASSURANCE,
    component:ServiceExplanationInssurance,
  },
  {
    title: "Tous vos autres courriers",
    img: "Photo_Autre_Courrier",
    btn: "Envoyer mon courrier",
    left: true,
    id: ids.COURRIER,
    component:ServiceExplanationLetterPapper
  },
];
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
await fetchData(FaqSpecificPage.SERVICES);
</script>
<template>
  <div class="page">
    <Hero
      :title="'Nos services'"
      :img="'images/Photo_Header_Femme_Enveloppe.webp'"
      :left="true"
    />
    <ServiceCardSection />
    <Api/>
    <GenerationStepSection />
    <ServiceExplanation
      v-for="(serviceExplanation, index) in servicesExplanationSections"
      :key="index"
      :title="serviceExplanation.title"
      :img="serviceExplanation.img"
      :id="serviceExplanation.id"
      :left="serviceExplanation.left"
      :btn="serviceExplanation.btn">
      <component :is="serviceExplanation.component"/>
    </ServiceExplanation>
    <AdvantageSection />
    <FaqByPage :faqs="faq" :loading="loading" />
  </div>
</template>
