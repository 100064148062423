import { HttpClient } from "jrmc/vue/shared/api/http-client";
import { FaqCategories } from "jrmc/vue/prelogin/types/faq";
import {SSRContext} from "@vue/server-renderer";

export class FaqCategoryApi extends HttpClient{
    public constructor(ssrContext?: SSRContext){
        super("/api/v1/faq_categories", ssrContext)
    }
    async getFaqCategories(){
        return this.instance.get<Array<FaqCategories>>('');
    }
}
