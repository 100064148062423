import { ref } from 'vue';
import { useSSRContext } from 'vue';
import { FaqApi } from "jrmc/vue/prelogin/api/faq";
import { FaqSpecificPage } from "jrmc/vue/prelogin/enums/faq";
import { Faq } from 'jrmc/vue/prelogin/types/faq';

export function useFaqData() {
  const faq = ref<Array<Faq>>([]);
  const loading = ref(true);

  const fetchData = async (faqSpecificPage : FaqSpecificPage) => {
    let ctx = undefined;
    if (import.meta.env.SSR) {
      ctx = useSSRContext();
    }
    const faqApi = new FaqApi(ctx);
    try {
      faq.value = await faqApi.getFaq(faqSpecificPage);
    } catch (error) {
      console.error('Failed to fetch FAQ:', error);
    } finally {
      loading.value = false;
    }
  };

  return {
    faq,
    loading,
    fetchData,
  };
}
