<script setup lang="ts">
import PreInscriptionComponent from "jrmc/vue/prelogin/components/PreInscription/PreInscriptionComponent.vue";
import analytics from "jrmc/vue/shared/services/analytics";
const components = [
  {
    title: "Professionnel de l’assurance",
    isNonInsuranceUser: false,
    content: `Si vous êtes assureur, agent général d’assurance, courtier, courtier grossiste, mutuelle, délégataire de gestion, etc. Cliquez ici !`,
  },
  {
    title: "Particulier ou professionnel",
    isNonInsuranceUser: true,
    content: `Vous êtes concerné par cette catégorie si vous êtes un particulier,  une entreprise, une collectivité,  une association, etc. agissant en dehors du secteur de l’assurance.`,
  },
];
const isSelected = defineModel('isSelected');
const isInsuranceModel = defineModel('isNonInsuranceUser')

const handleClickUserSelection = (isInsuranceUser: boolean)=>{
  analytics.event('insurance_user_or_not_choice', {
    'preRegisterType': isInsuranceUser ? 'INSURANCE' : 'OTHER'
  })

  isSelected.value = true
  isInsuranceModel.value = isInsuranceUser
}
</script>
<template>
  <section>
    <div class="container">
      <h1 data-test="registration-title">Inscrivez-vous</h1>
      <div class="preinscription-container">
        <PreInscriptionComponent v-for="component in components" v-bind="component" @click="handleClickUserSelection(component.isNonInsuranceUser)"/>
      </div>
    </div>
  </section>
</template>
<style scoped>
section{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4rem;
}
h1{
  font-size: clamp(1.5rem,3vw,2.2rem);
  text-align: center;
}
.preinscription-container{
  margin-top: max(6rem,10vh);
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 2rem;
}
@media screen and (max-width: 1024px) {
  .preinscription-container{
    margin-top: max(2rem, 2vh);
    display: flex;
    flex-direction: column;
  }
}
</style>
