export const Organisation = {
    "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://www.jeresiliemoncontrat.com",
      "sameAs": ["https://www.linkedin.com/company/jeresiliemoncontrat-com",
                 "https://fr.trustpilot.com/review/jeresiliemoncontrat.com",
                 "https://www.facebook.com/JeResilieMonContrat"
                ],
      "logo": "https://www.jeresiliemoncontrat.com/static/logo.png",
      "name": "JeResilieMonContrat.com",
      "alternateName": "JRMC",
      "legalName": "Clic'issimo",
      "description": "Le spécialiste de la résiliation des contrats d’assurance ! JRMC vous facilite vos démarches de résiliation.",
      "email": "contact@jeresiliemoncontrat.com",
      "telephone": "+33-4-80-80-01-12",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "7 Chem. de la Trière",
        "addressLocality": "Meylan",
        "addressCountry": "FR",
        "addressRegion": "Auvergne-Rhône-Alpes",
        "postalCode": "38240"
      },
      "contactPoint": {
      "@type": "ContactPoint",
      "email": "contact@jeresiliemoncontrat.com"
},
      "vatID": "FR68810506055",
      "iso6523Code": "0002:724500PMK2A2M1SQQ228"
}
