<script setup lang="ts">
import Hero from "jrmc/vue/prelogin/components/Faq/Hero.vue";
import FaqCategorieSection from "jrmc/vue/prelogin/components/Faq/FaqCategorieSection.vue";
import FaqSection from "jrmc/vue/prelogin/components/Faq/FaqSection.vue";
import {
  ref,
  useSSRContext,
} from "vue";
import { FaqCategories, Faq } from "jrmc/vue/prelogin/types/faq";
import { FaqCategoryApi } from "jrmc/vue/prelogin/api/faq-categories";
import { FaqApi } from "jrmc/vue/prelogin/api/faq";
import Loader from "jrmc/vue/prelogin/components/Loader.vue";
import { setMetaTags } from "jrmc/vue/prelogin/seo/seo.server";
import {useRoute} from "vue-router";
const faqCategories = ref<Array<FaqCategories>>([]);
const faq = ref<Array<Faq>>([]);
const loading = ref(true);
const route = useRoute();
const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);

const fetchData = async () => {
  let ctx = undefined;
  if (import.meta.env.SSR) {
    ctx = useSSRContext();
  }
  const faqCategoryApi = new FaqCategoryApi(ctx);
  const faqApi = new FaqApi(ctx);
  faqCategories.value = await faqCategoryApi.getFaqCategories();
  faq.value = await faqApi.getFaq();
  loading.value = false;
};
await fetchData();
</script>

<template>
  <div class="page">
    <Hero :title="'Des questions ?'" />
    <template v-if="loading">
      <Loader />
    </template>
    <template v-else>
      <FaqCategorieSection :faq-categories="faqCategories" />
      <FaqSection :faq="faq" :faq-categories="faqCategories" />
    </template>
  </div>
</template>
