<script setup lang="ts">
import ServiceComponent from "jrmc/vue/prelogin/components/Home/ServiceComponent.vue";
const serviceCardContent = [
  {
    title: "Résiliations de contrats d’assurance",
    description: `<strong>Générez, envoyez, suivez et assurez-vous de la bonne réception</strong> des demandes
        de résiliation de tous types de contrats d’assurance (hors assurance emprunteur).  `,
    icon: 'cancellation_letter_icon.svg',
    alt:'Icone de résiliation de contrats'
  },
  {
    title: "Changement d’assurance emprunteur",
    description: `<strong>Générez et envoyez</strong> facilement des <strong>lettres de substitution</strong>
        vers les établissements bancaires.
        Et si besoin, nous vous permettons d’<strong>envoyer la lettre de résiliation</strong> du contrat à l’ancien assureur.`,
    icon: 'Picto_Carte_Assurance_Emprunteur.svg',
    alt:'icone de changement d’assurance emprunteur'
  },
  {
    title: "Tous vos autres courriers",
    description: `Ce service vous permet <strong>d’envoyer par internet, tous types de courriers au destinataire de votre choix.</strong>`,
    icon: 'other_letter_icon.svg',
    alt:'icone de autres courriers'
  },
];
</script>
<template>
  <section>
    <div class="container services-container">
      <h2>Découvrez ou redécouvrez nos services</h2>
      <p>Des services conçus pour optimiser et accélérer vos procédures de résiliation</p>
      <div class="service-card-container">
        <ServiceComponent
          v-for="cardContent in serviceCardContent"
          v-bind="cardContent"
        >
        </ServiceComponent>
      </div>
    </div>
  </section>
</template>
<style scoped>
.services-container > p {
  color: var(--color-orange);
  font-weight: 700;
}
.service-card-container {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
}
@media screen and (max-width: 1024px) {
  .service-card-container {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
