<script setup lang="ts">
import useAssets from "../../composable/useAssets";
import ReinsuranceComponent from "../ReinsuranceComponent.vue";
const elements = [
  {
    img: "Picto_Destinataire.svg",
    title: "+900",
    text: "destinataires",
  },
  {
    img: "paper-plane-orange.svg",
    title: "+7.5M",
    text: "d'envois",
  },
  {
    img: "Picto_Expertise.svg",
    title: "+10 ans",
    text: "d'expérience",
  },
];
</script>
<template>
  <section>
    <div class="container reinssurance-about-container">
      <div class="left">
        <p>À propos de nous</p>
        <h2>
          Plus de 13 000 professionnels de l’assurance font confiance à JRMC en
          France et dans les DROM-COM
        </h2>
        <p>
          Le service en ligne de résiliation de contrats d’assurance à
          destination des professionnels de l’assurance.
        </p>
        <div class="reinsurance-elements">
          <ReinsuranceComponent
            v-for="(element, index) in elements"
            v-bind="element"
            :isBordered="index === elements.length - 1"
          />
        </div>
      </div>
      <div class="right">
        <img :src="useAssets('images/about-us-reinsurrance.webp')" alt="">
      </div>
    </div>
  </section>
</template>
<style scoped>
.reinssurance-about-container{
    padding: 3rem;
    border-radius: var(--border-radius);
    background-color: var(--color-light-blue-primary);
   display: flex;
    align-items: center;
    gap: 2rem;
}
.right{
  flex-shrink: 0.6;
}
.left{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.left h2{
   font-size: clamp(1.4rem, 2vw, 1.6rem);
}
.reinsurance-elements {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width:1240px) {
  .reinssurance-about-container{
    flex-wrap: wrap-reverse;
    justify-content: center;
  }
  .right{
    flex-shrink: 1;
  }
}
@media screen and (max-width: 650px) {
    .reinsurance-elements {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .reinsurance-element:nth-last-child(1) {
      grid-column: 1 / span 2;
    }
  }
</style>
