<script setup lang="ts">
import AdvantageComponent from "jrmc/vue/prelogin/components/Service/AdvantageComponent.vue";
const advantages = [
  {
    title: "Une équipe experte et disponible",
    text: `Une question ? Notre équipe est à votre disposition du lundi au vendredi de 09h à 12h et de 14h à 18h (horaires France Métropolitaine).
    Contactez nous depuis la rubrique aide de notre site ou via le formulaire de contact.`,
    icon: "Picto_Avantages_Casque_Support.svg",
    isOrange: true,
  },
  {
    title: "Prestataire de service de confiance qualifié selon le règlement eiDAS",
    text: `Tous nos courriers électroniques sont envoyés par notre tiers de confiance et partenaire qualifié par l’Agence Nationale de la Sécurité
    des Systèmes d’Information (ANSSI). Cette conformité à la réglementation européenne applicable en matière de lettre recommandée électronique,
    garantit la sécurité et la confidentialité de vos correspondances. `,
    icon: "Picto_Avantages_Valide_Partenaire.svg",
    isOrange: false,
  },
  {
    title: "Accessible 24h/24 et 7j/7",
    text: `Notre service JeResilieMonContrat.com est accessible 24h/24 et 7j/7 et vous permet ainsi d’envoyer vos lettres
    de résiliation à toute heure, y compris les week-ends et jours fériés.`,
    icon: "Picto_Avantages_Envoi_24_7.svg",
    isOrange: false,
  },
  {
    title: "+900 destinataires",
    text: `Ne vous souciez plus des coordonnées de votre destinataire. Nous vous mettons à disposition notre annuaire de destinataires
    composé de plus de 900 références et vous n'avez plus qu'à choisir celui qui sera destinataire de votre lettre de résiliation.`,
    icon: "Picto_Avantages_Avion_Destinataires.svg",
    isOrange: true,
  },
];
</script>

<template>
  <section>
    <div class="container">
      <h2>Les atouts clés de JRMC : fiabilité et performance au quotidien</h2>
      <div class="advantage-container">
        <AdvantageComponent
          v-for="(advantage, index) in advantages"
          :key="index"
          v-bind="advantage"
        />
      </div>
    </div>
  </section>
</template>

<style scoped>
h2 {
  text-align: center;
}
.advantage-container {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
}
@media screen and (max-width: 1024px) {
  .advantage-container {
    display: flex;
    flex-direction: column;
  }
}
</style>
