export default function useAssets(asset: string) {
  const absolutAsset =`/assets/frontend/vue/prelogin/assets/${asset}`
  const assets =  import.meta.glob("/assets/frontend/vue/prelogin/assets/**/*", {eager: true});
  const getAssetUrl = () => {
   if (assets[absolutAsset]) {
     //@ts-ignore
     return assets[absolutAsset].default
   }
  }

  return getAssetUrl()
}
