<script setup lang='ts'>
import { Routes } from 'jrmc/vue/prelogin/enums/routes';
import CustomerTypologieCard from 'jrmc/vue/prelogin/components/Home/CustomerTypologieCard.vue';

const customers = [
    {
        img: 'Picto_Assureurs.svg',
        name: 'Assureurs'
    },
    {
        img: 'Picto_Agents_Generaux.svg',
        name: "Agents généraux d'assurance"
    },
    {
        img: 'Picto_Courtier.svg',
        name: 'Courtiers'
    },
    {
        img: 'Picto_Courtier_Grossiste.svg',
        name: 'Courtiers grossistes'
    },
    {
        img: 'Picto_Mutuelle.svg',
        name: 'Mutuelles'
    },
    {
        img: 'Picto_Delegataire.svg',
        name: 'Délégataires de gestion'
    },
]
</script>
<template>
    <section>
        <div class="container typologie-section-container">
            <h2>Une expertise sur-mesure pour tous les professionnels de l’assurance</h2>
            <div class="customer-container">
                <CustomerTypologieCard v-for="customer in customers" v-bind="customer"/>
            </div>
            <RouterLink :to="{name: Routes.INSCRIPTION}" class="btn btn-blue">Envoyer mes lettres de résiliation</RouterLink>
        </div>
    </section>
</template>
<style scoped>
.typologie-section-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.typologie-section-container a{
    margin-top: 0.8rem;
    align-self: flex-end;
}
.customer-container{
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: 1fr 1fr;
    gap: 2rem;
}
@media screen and (max-width: 1024px) {
    .customer-container{
        grid-template-columns: 1fr 1fr
    }
}
@media screen and (max-width: 650px) {
    .customer-container{
        gap: 0.8rem;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
    .typologie-section-container a{
        margin-top: 0.5rem;
        align-self: stretch;
    }
}
</style>
