import { HttpClient } from "jrmc/vue/shared/api/http-client";
import { GenericResponse, PlainPassword } from "jrmc/vue/prelogin/types/inscription-form";

export default new class ResetPasswordApi extends HttpClient{
    public constructor(){
        super("/api/v1/reset_password")
    }

    async sendPasswordResetEmail(username : string){
        return this.instance.post<any>('/send_mail',{username})
    }

    async resetPassword(confirmationToken : string, plainPassword: PlainPassword){
        return this.instance.post<GenericResponse>('',{token : confirmationToken, plainPassword})
    }
}