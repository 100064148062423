<script setup lang="ts">
import {onBeforeUnmount, onMounted, ref} from "vue";
import { Routes } from "jrmc/vue/prelogin/enums/routes";
import { useRoute } from "vue-router";
import useAssets from "jrmc/vue/prelogin/composable/useAssets";
import axios from "axios";

type NavLink = {
  title: string;
  link: string;
};

let userConnected = ref(false);

const navLinks: Array<NavLink> = [
  {
    title: "Services",
    link: Routes.SERVICES,
  },
  {
    title: "Qui sommes-nous ?",
    link: Routes.ABOUT,
  },
  {
    title: "Tarifs",
    link: Routes.TARIFS,
  },
];
const route = useRoute();
const isOpen = ref<boolean>(false);
const isMobile = ref<boolean>(false);
const isScrollDown = ref<boolean>(false);
const onScroll = () => {
  isScrollDown.value = window.scrollY > 2;
};
const checkIfMobile = () => {
  isMobile.value = window.innerWidth <= 1024;
};
onMounted(async () => {
  // Hack to know if a user is connected, needs to be added in a store
  try {
    const response = await axios.get<any>('/api/v1/connected');
    userConnected.value = response.data.connected;
  } catch (error: any) {
  }

  checkIfMobile();
  window.addEventListener("resize", checkIfMobile);
  document.addEventListener("scroll", onScroll);
});
onBeforeUnmount(() => {
  document.removeEventListener("scroll", onScroll);
  window.removeEventListener("resize", checkIfMobile);
});

</script>
<template>
  <nav :class="[isScrollDown ? 'scroll' : '']">
    <div class="container navbar-container">
      <RouterLink
        :to="{ name: Routes.HOME }"
        @click="isOpen = false"
        aria-label="Accueil"
      >
        <h1 v-if="route.name === Routes.HOME">
          <img
            class="logo"
            :src="useAssets('images/Header_Logo_JRMC.svg')"
            alt="JeRésilieMonContrat : service de résiliation de Contrats d'assurance par internet"
            width="112"
            height="27"
          />
        </h1>
        <img
          v-else
          class="logo"
          :src="useAssets('images/Header_Logo_JRMC.svg')"
          alt="JeRésilieMonContrat : service de résiliation de Contrats d'assurance par internet"
          width="112"
          height="27"
        />
      </RouterLink>
      <Transition name="navbar">
        <div
          :class="[isOpen ? 'mobile-menu-is-open' : '', 'links-and-ctas']"
          v-if="!isMobile || isOpen"
        >
          <ul class="links">
            <li
              v-for="navLink in navLinks"
              @click="isOpen = false"
            >
              <RouterLink :to="{ name: navLink.link }" exact-active-class="active-link">{{
                navLink.title
              }}</RouterLink>
            </li>
          </ul>
          <div class="ctas">
            <template v-if="userConnected">
              <a
                class="btn btn-transparent-blue"
                href="/ticket/create"
                @click="isOpen = false"
              >Contact</a
              >
              <a
                class="btn btn-blue"
                href="/"
                @click="isOpen = false"
              >Mon compte</a
              >
            </template>
            <template v-else>
              <RouterLink
                class="btn btn-transparent-blue"
                :to="{ name: Routes.CONTACT }"
                @click="isOpen = false"
              >Contact</RouterLink
              >
              <RouterLink
                class="btn btn-blue"
                :to="{ name: Routes.INSCRIPTION }"
                @click="isOpen = false"
              >S'inscrire</RouterLink
              >
              <RouterLink
                class="underline"
                :to="{ name: Routes.CONNEXION }"
                @click="isOpen = false"
              >Connexion</RouterLink
              >
            </template>
          </div>
        </div>
      </Transition>
      <div class="mobile-menu-icons">
        <img
          v-if="!isOpen"
          height="27"
          width="24"
          :src="useAssets('images/bars-solid.svg')"
          alt="hamburger icon"
          @click="isOpen = true"
        />
        <img
          v-else
          :src="useAssets('images/xmark-solid.svg')"
          alt="close menu icon"
          height="32"
          width="24"
          @click="isOpen = false"
        />
      </div>
    </div>
  </nav>
</template>
