<script setup lang="ts">
import Input from "jrmc/vue/prelogin/components/Fields/Input.vue";
import {ErrorMessage, Field} from "vee-validate";
import GroupApi from 'jrmc/vue/prelogin/api/group'
import Select from "jrmc/vue/prelogin/components/Fields/Select.vue";
defineEmits(["handleClickContinue"]);
defineProps<{
  isNonInsuranceUser: boolean | null,
  isLoading: boolean;
}>();

let affiliations = await GroupApi.getAffiliations();

</script>
<template>
  <div class="second-bloc-container">
    <Input :name="'address'" :type="'text'" :placeholder="'Adresse*'" autocomplete="address-line1" />
    <div class="city-zipcode">
      <Input :name="'postCode'" :type="'text'" :placeholder="'Code postal*'" autocomplete="postal-code" />
      <Input :name="'city'" :type="'text'" :placeholder="'Ville*'" autocomplete="address-level2" />
    </div>
    <div class="phone-numbers">
      <Input :name="'phone'" :type="'text'" :placeholder="'Téléphone*'" autocomplete="tel-national" />
      <Input
        :name="'portable'"
        :type="'text'"
        :placeholder="'Téléphone portable'"
      />
    </div>
    <div v-if="affiliations">
      <Select v-if="!isNonInsuranceUser" name="affiliation" id="affiliation" label="Votre affiliation" :default-value="affiliations[0].id">
        <option v-for="affiliation in affiliations" :value="affiliation.id" :key="affiliation.id">{{ affiliation.name }}</option>
      </Select>
    </div>
    <div id="cgv-block">
      <div id="cgv-field">
        <Field v-slot="{ field }" name="cgvAccepted" type="checkbox" :value="true" :unchecked-value="false">
          <label>
            <input v-bind="field" name="cgvAccepted" :value="true" type="checkbox">
            Je reconnais avoir lu et compris <a class="link" target="_blank" href="/api/v1/cgv/download">les CGU et les CGV</a> et je les accepte
          </label>
        </Field>
      </div>
      <ErrorMessage class="error" name="cgvAccepted"></ErrorMessage>
    </div>
    <div class="cta-inscription">
      <button
        :class="[isLoading ? 'loading' : '', 'btn-lg', 'btn-blue']"
        :disabled="isLoading"
      >
        S'inscrire
      </button>
      <p class="underline" @click.prevent="$emit('handleClickContinue', 0)">
        Retour
      </p>
    </div>
  </div>
</template>
<style scoped>
.second-bloc-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.city-zipcode,
.phone-numbers {
  display: flex;
  gap: 0.5rem;
}
.city-zipcode:deep(.input),
.phone-numbers:deep(.input) {
  width: 100%;
}

#cgv-field {
  display: flex;
  gap: 0.5rem;
}

.cta-inscription {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  gap: 0.6rem;
}
.cta-inscription button {
  width: 100%;
}
.cta-inscription p {
  cursor: pointer;
}

.link {
  color: var(--color-orange);
  text-decoration: underline;
}

@media screen and (max-width: 1024px) {
  .city-zipcode, .phone-numbers{
   flex-wrap: wrap;
  }
}
</style>
