<script setup lang="ts"></script>

<template>
  <svg
    width="59"
    height="61"
    viewBox="0 0 59 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 55.2894C0 38.7262 0 22.1645 0 5.60139C0.0360806 5.5148 0.0880368 5.43261 0.105355 5.34307C0.391114 3.91352 1.10118 2.75109 2.19515 1.81175C3.03655 1.08964 4.03815 0.738855 5.07005 0.445312C21.3569 0.445312 37.6422 0.445312 53.929 0.445312C53.994 0.47907 54.0575 0.528972 54.1267 0.545117C57.0854 1.18357 58.9976 3.55687 58.9976 6.6332C59.002 22.5095 59.0005 38.3857 58.9919 54.262C58.9919 54.8036 58.9385 55.3584 58.8086 55.8823C58.1274 58.6431 55.7691 60.444 52.861 60.444C37.2886 60.4469 21.7148 60.444 6.14237 60.444C5.96918 60.444 5.796 60.4469 5.62425 60.4352C3.97176 60.3325 2.60935 59.6309 1.49518 58.3936C0.694192 57.5056 0.301634 56.4225 0 55.2894ZM50.1175 51.4146C50.1276 51.24 50.1391 51.124 50.1391 51.0095C50.1391 45.463 50.1636 39.918 50.1305 34.3715C50.1088 30.7125 48.54 27.8123 45.6146 25.734C42.4597 23.4914 38.5471 23.0408 35.0588 25.4757C34.128 26.1259 33.3327 26.9757 32.4654 27.7419V24.4894H23.6588V51.4044H32.4553V50.6852C32.4553 45.8417 32.4553 40.9997 32.4567 36.1563C32.4567 35.7659 32.4683 35.374 32.5043 34.9865C32.7511 32.3842 35.5972 30.4659 38.0449 31.2453C40.0928 31.897 41.2864 33.5922 41.2892 35.8891C41.2965 40.8295 41.2907 45.7698 41.2921 50.7116C41.2921 50.9391 41.2921 51.1666 41.2921 51.4132H50.116L50.1175 51.4146ZM17.6636 51.4146V24.4982H8.89893V51.4146H17.6636ZM18.5685 13.9409C18.5642 10.9585 16.1627 8.51921 13.2575 8.54563C10.3508 8.57205 8.00413 10.9556 7.96661 13.9189C7.92908 16.8676 10.3292 19.3187 13.2632 19.3289C16.2017 19.3392 18.5729 16.9307 18.5685 13.9409Z"
      fill="#F39422"
    />
  </svg>
</template>

<style scoped></style>
