import { HttpClient } from "jrmc/vue/shared/api/http-client";
import { Faq } from "jrmc/vue/prelogin/types/faq";
import { FaqSpecificPage } from "jrmc/vue/prelogin/enums/faq";
import {SSRContext} from "@vue/server-renderer";

export class FaqApi extends HttpClient{
    public constructor(ssrContext?: SSRContext){
        super("/api/v1/faqs", ssrContext)
    }
    async getFaq(specificPage? : FaqSpecificPage){
        if(specificPage){
            const parameters = { specificPage }
            return this.instance.get<Array<Faq>>('',{params: parameters});
        }else{
            return this.instance.get<Array<Faq>>('');
        }
    }
}
