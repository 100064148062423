<script setup lang="ts">
import LogoWhite from "./Icons/LogoWhite.vue";
import LinkedinIcon from "./Icons/LinkedinIcon.vue";
import { Routes } from "jrmc/vue/prelogin/enums/routes";

enum LinkType {
  ROUTE = "route",
  LINK = "LINK",
}

const footerLinks = [
  {
    categorie: "Aide",
    links: [
    {
        title: "Questions fréquentes",
        linkType: LinkType.ROUTE,
        link: Routes.FAQ,
      },
      {
        title: "Contact",
        linkType: LinkType.ROUTE,
        link: Routes.CONTACT,
      }
    ],
  },
  {
    categorie: "Explorer",
    links: [
      {
        title: "Inscription",
        linkType: LinkType.ROUTE,
        link: Routes.INSCRIPTION,
      },
      {
        title: "Connexion",
        linkType: LinkType.ROUTE,
        link: Routes.CONNEXION,
      },
      {
        title: "Qui sommes-nous ?",
        linkType: LinkType.ROUTE,
        link: Routes.ABOUT,
      },
    ],
  },
  {
    categorie: "Pages utiles",
    links: [
    {
    title: "Mentions légales",
    linkType: LinkType.ROUTE,
    link: Routes.LEGAL,
  },
  {
    title: "Politique de confidentialité",
    linkType: LinkType.ROUTE,
    link: Routes.PRIVACY,
  },
  {
    title: "Politique de cookies",
    linkType: LinkType.ROUTE,
    link: Routes.COOKIES,
  },
  {
    title: "Conditions d'utilisations",
    linkType: LinkType.LINK,
    link: "api/v1/cgv/download",
  },
    ],
  },
];
</script>

<template>
  <footer class="footer-prelog">
    <div class="container footer-container">
      <LogoWhite />
      <ul v-for="footerLink in footerLinks">
        <li class="footer-categorie">{{ footerLink.categorie }}</li>
        <li v-for="link in footerLink.links">
          <RouterLink
            v-if="link.linkType === LinkType.ROUTE"
            :to="{ name: link.link }"
            >{{ link.title }}</RouterLink
          >
          <a v-else-if="link.linkType === LinkType.LINK" :href="link.link" target="_blank">{{
            link.title
          }}</a>
        </li>
      </ul>
      <a
        class="linkedin"
        href="https://www.linkedin.com/company/jeresiliemoncontrat-com/"
        target="_blank"
      >
        <LinkedinIcon /> Nous suivre !
      </a>
    </div>
    <!-- <ul class="container legals">
      <li v-for="legal in legals">
        <RouterLink :to="{ name: legal.link }">{{ legal.title }}</RouterLink>
      </li>
      <li><a href="api/v1/cgv/download">Conditions d'utilisations</a></li>
    </ul> -->
    <p class="container copy">&copy; JeResilieMonContrat.com 2024</p>
  </footer>
</template>

<style scoped></style>
