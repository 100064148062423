<script setup lang="ts">
</script>

<template>
  <div class="container succes-message-container">
    <h2>Merci d’avoir pris contact avec nous !</h2>
    <p>
      Votre message a bien été pris en compte, nous nous engageons à vous
      répondre sous 24h !
    </p>
    <RouterLink to="/" class="btn btn-blue">Retour à l'accueil</RouterLink>
  </div>
</template>

<style scoped>
.succes-message-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  text-align: center;
}
.succes-message-container h2 {
  font-size: clamp(1.1rem, 2.5vw, 1.8rem);
}
.succes-message-container p {
  width: 95%;
}
.succes-message-container a {
  width: fit-content;
}
</style>
