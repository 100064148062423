<script setup lang="ts">
import { ContactReason } from "jrmc/vue/prelogin/enums/contact";
import SuccesMessage from "jrmc/vue/prelogin/components/Contact/SuccesMessage.vue";
import ContactApi from "jrmc/vue/prelogin/api/contact";
import { ref } from "vue";
import { useForm } from "vee-validate";
import { ContactForm } from "jrmc/vue/prelogin/models/ContactForm";
import analytics from "jrmc/vue/shared/services/analytics";
import {AxiosError} from "axios";
import SweetAlert2 from "sweetalert2";

const initialValues: ContactForm = new ContactForm();
const isSend = ref<boolean>(false);
const { defineField, errors, handleSubmit } = useForm({
  initialValues,
  validationSchema: {
    email: 'email|required',
    name: 'required',
    surname: 'required',
    content: 'required',
    consent: (val:boolean) => val ? true : "Ce champ est requis.",
    phoneNumber: 'phone|required',
  },
});
const [email, emailAttrs] = defineField("email");
const [surname, surnameAttrs] = defineField("surname");
const [name, nameAttrs] = defineField("name");
const [phone, phoneAttrs] = defineField("phoneNumber");
const [content, contentAttrs] = defineField("content");
const [category, categoryAttrs] = defineField("category");
const [consent, consentAttrs] = defineField("consent");
const onSubmitContact = handleSubmit(async (values) => {
  try {
    await ContactApi.postContactMessage(values);
    analytics.event('contact_form_submission', {
      'contact_form_category': values['category']
    })
    isSend.value = true;
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 429) {
      SweetAlert2.fire('Erreur', 'Trop de tentatives, merci de réessayer dans un moment', 'error')
    } else {
      SweetAlert2.fire('Erreur', 'Une erreur est survenue lors de l\'envoi de votre demande de contact', 'error')
    }
  }
});
</script>

<template>
  <div v-if="isSend" class="sent-message-section">
    <SuccesMessage />
  </div>
  <div v-else class="contact-form-container">
    <h1>Formulaire de contact</h1>
    <p>
      Vous avez une question ou envie d’échanger avec nos équipes ? Laissez nous un message avec vos coordonnées et
      nous vous recontacterons dans les plus brefs délais.
    </p>
    <form @submit="onSubmitContact">
      <div class="fields">
        <div class="contact-reason-field">
            <label for="reason-select"
              >Pourquoi souhaitez-vous nous contacter ?</label
            >
            <div class="select-field-wrapper">
              <div class="select-field">
                <select
                  name="reason-select"
                  id="reason-select"
                  v-bind="categoryAttrs"
                  v-model="category"
                >
                  <option
                    v-for="reson in Object.values(ContactReason)"
                    :value="reson"
                  >
                    {{ reson }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        <div class="name">
          <div class="input">
            <p class="error" v-if="errors.name">{{ errors.name }}</p>
            <input
              :class="[errors.name ? 'error-field' : '']"
              type="text"
              name="name"
              placeholder="Nom*"
              v-bind="nameAttrs"
              v-model="name"
            />
          </div>
          <div class="input">
            <p class="error" v-if="errors.surname">{{ errors.surname }}</p>
            <input
              :class="[errors.surname ? 'error-field' : '']"
              type="text"
              name="surname"
              placeholder="Prénom*"
              v-bind="surnameAttrs"
              v-model="surname"
            />
          </div>
        </div>
        <div class="phone-and-email">
          <div class="input">
            <p class="error" v-if="errors.email">{{ errors.email }}</p>
            <input
              :class="[errors.email ? 'error-field' : '']"
              name="email"
              v-bind="emailAttrs"
              v-model="email"
              placeholder="Adresse mail*"
            />
          </div>
          <div class="input">
            <p class="error" v-if="errors.phoneNumber">
              {{ errors.phoneNumber }}
            </p>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Téléphone*"
              v-bind="phoneAttrs"
              :class="[errors.phoneNumber ? 'error-field' : '']"
              v-model="phone"
            />
          </div>
        </div>
      </div>
      <p class="error" v-if="errors.content">{{ errors.content }}</p>
      <textarea
        :class="[errors.content ? 'error-field' : '']"
        name="text"
        placeholder="Bonjour, je souhaiterais avoir des informations concernant...."
        v-bind="contentAttrs"
        v-model="content"
      ></textarea>
      <div class="consent-input">
       <p v-if="errors.consent" class="error">{{ errors.consent }}</p>
       <div :class="[errors.content ? 'error-field' : '', 'consent']">
         <input type="checkbox" name="consent" id="consent" v-bind="consentAttrs" v-model="consent">
         <label for="consent">J’accepte que les données transmises dans le formulaire puissent être utilisées par
           JeResilieMonContrat.com pour vous contacter. Pour plus d'informations sur le traitement des données, consultez <RouterLink class="underline" to="/politique-de-confidentialite">notre Politique de confidentialité</RouterLink>.*</label>
       </div>
      </div>
      <button class="btn btn-blue">Envoyer</button>
    </form>
  </div>
</template>

<style scoped>
* {
  --small-font-size: clamp(0.5rem, 1vw, 0.8rem);
}

.contact-form-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form-container h1 {
  font-size: 1.5rem;
}

.contact-form-container p {
  font-size: clamp(0.7rem, 1.2vw, 1rem);
}

.contact-form-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-form-container textarea {
  min-height: 8rem;
}

.contact-form-container button {
  width: fit-content;
  align-self: flex-end;
}
.fields {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.name, .phone-and-email{
  display: flex;
  gap: 1rem;
  width: 100%;
}
.input{
  width: 100%;
}
.input input,
textarea {
  padding: 0.7rem;
  background-color: var(--color-white);
  border-radius: 15px;
  border: 2px var(--color-dark-blue-primary) solid;
  width: 100%;
  resize: none;
}

.contact-reason-field {
  font-size: var(--small-font-size);
  font-weight: 600;
  color: var(--color-dark-blue-secondary);
}

.contact-reason-field select {
  width: 100%;
  background-color: transparent;
  text-transform: capitalize;
  color: var(--color-dark-blue-secondary);
}
.select-field-wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}
.select-field {
  margin-top: 0.5rem;
  background-color: var(--color-white);
  padding: 0.5rem;
  border-radius: 15px;
  border: 2px var(--color-dark-blue-primary) solid;
}
.consent{
  display: flex;
  gap: 0.6rem;
  align-items: baseline;
}
.consent label{
  color: var(--color-dark-blue-secondary);
  font-size: var(--small-font-size);
}
@media screen and (max-width: 1024px) {
  * {
    --small-font-size: clamp(0.7rem, 1.2vw, 1rem);
  }
  .select-field-wrapper{
    display: block;
  }
  .name, .phone-and-email{
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 650px) {
  .fields {
    flex-wrap: wrap;
    gap: 0.5rem;
  }
}
</style>
