<script setup lang="ts"></script>
<template>
  <p>
    Générez et envoyez facilement des lettres de substitution vers les établissements bancaires.
  </p>
  <p>
    Notre service vous aide également à résilier votre contrat d’assurance
    emprunteur auprès de l’ancien assureur, garantissant ainsi une transaction plus fluide et conforme aux réglementations.
  </p>
</template>
