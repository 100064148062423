<script setup lang="ts">
import { Faq, FaqCategories } from "jrmc/vue/prelogin/types/faq";
import {computed} from "vue";
import FaqQuestionAnswerComponent from "jrmc/vue/prelogin/components/Faq/FaqQuestionAnswerComponent.vue";
const props = defineProps<{
  questions: Faq[];
  category: FaqCategories;
}>();
// We want to exclude page specific FAQ
const filteredQuestions = computed(()=>{
  return props.questions.filter((question: Faq) => {
    return !question.specificPage
  })
})

</script>

<template>
  <div class="faq-section" :id="`section-${category.id}`" style="scroll-margin-top: 100px">
    <h2>{{ category.name }}</h2>
    <FaqQuestionAnswerComponent v-for="(question, index) in filteredQuestions" :key="index" :question="question" :in-faq-page="true"/>
  </div>
</template>

<style scoped>
.faq-section {
  margin-top: 5rem;
}
.faq-section h2 {
  margin-bottom: 2rem;
}
</style>
