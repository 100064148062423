import {RouteRecordRaw} from "vue-router";
import Layout from "jrmc/vue/prelogin/pages/Layout.vue";
import { Routes } from "jrmc/vue/prelogin/enums/routes";

const routes : Array<RouteRecordRaw> = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                component: ()=> import("jrmc/vue/prelogin/pages/Home.vue"),
                meta: {
                  pageTitle: "Résiliation de contrats d'assurance en ligne",
                  pageDescription: `Avec JeResilieMonContrat.com, résiliez facilement les contrats d’assurance de vos anciens et futurs clients dans le cadre d’une reprise de contrat.`
                },
                name: Routes.HOME
            },
            {
                path: '/a-propos',
                component: ()=> import("jrmc/vue/prelogin/pages/About.vue"),
                meta: {
                  pageTitle: 'Qui sommes-nous',
                  pageDescription: 'Découvrez l’équipe de JeResilieMonContrat.com, ses missions et son engagement ainsi que l’histoire du spécialiste de la résiliation de contrats d’assurance.'
                },
                name: Routes.ABOUT
            },
            {
                path: '/services',
                component: ()=> import("jrmc/vue/prelogin/pages/Service.vue"),
                meta: {
                  pageTitle: 'Nos services',
                  pageDescription: 'Complémentaire santé, assurance automobile, habitation propriétaire/locataire, etc. Résiliez tous les anciens contrats d’assurance de vos clients avec JRMC. '
                },
                name: Routes.SERVICES
            },
            {
                path: '/tarifs',
                component: ()=> import("jrmc/vue/prelogin/pages/Tarifs.vue"),
                meta: {
                  pageTitle: 'Tarifs pour lettres recommandées en ligne et courriers',
                  pageDescription: `JRMC vous assure des prix compétitifs gages de performance. Consultez tous nos prix de lettres recommandées en ligne avec ou sans accusé de réception.`
                },
                name: Routes.TARIFS
            },
            {
                path: '/contact',
                component: ()=> import("jrmc/vue/prelogin/pages/Contact.vue"),
                meta: {
                  pageTitle: 'Contact',
                  pageDescription: `Vous avez une question ou envie d’échanger avec nos équipes ? Laissez nous un message avec vos coordonnées et nous vous contacterons dans les plus brefs délais. `
                },
                name: Routes.CONTACT
            },
            {
                path: '/faq',
                component: ()=> import("jrmc/vue/prelogin/pages/Faq.vue"),
                meta: {
                  pageTitle: 'Faq',
                  pageDescription: `Vous avez une question sur notre solution ? Notre FAQ complète et détaillée répondra à toutes vos interrogations.`
                },
                name: Routes.FAQ
            },
            {
                path: '/connexion',
                component: ()=> import("jrmc/vue/prelogin/pages/Connexion.vue"),
                meta: {
                  pageTitle: 'Connexion',
                  pageDescription: `Résiliez dès maintenant les anciens contrats d’assurance de vos clients, actuels et futurs avec JeResilieMonContrat.com. `
                },
                name: Routes.CONNEXION
            },
            {
                path: '/inscription',
                component: ()=> import("jrmc/vue/prelogin/pages/Inscription.vue"),
                meta: {
                  pageTitle: 'Inscription',
                  pageDescription: ` Inscrivez-vous en quelques clics à notre solution simple et intuitive de résiliation de contrats d’assurance par lettre recommandée électronique. `
                },
                name: Routes.INSCRIPTION
            },
            {
                path: '/reinitialisation',
                component: ()=> import("jrmc/vue/prelogin/pages/ResetPassword.vue"),
                meta: {
                  pageTitle: 'Réinitialisation',
                  pageDescription: 'Réinitialisez votre mot de passe'
                },
                name: Routes.REINITIALISATION
            },
            {
                path: '/reinitialisation/mot-de-passe/:token',
                component: ()=> import("jrmc/vue/prelogin/pages/ResetPasswordSubmission.vue"),
                meta: {
                  pageTitle: 'Demande de réinitialisation',
                  pageDescription: 'Envoyez votre réinitialisation de mot de passe'
                },
                name: Routes.REINITIALISATION_SUBMISSION
            },
            {
                path: '/activation/:token',
                component: ()=> import("jrmc/vue/prelogin/pages/Activation.vue"),
                meta: {
                  pageTitle: 'Activation',
                  pageDescription: 'Activez votre compte'
                },
                name: Routes.ACTIVATION
            },
            {
                path: '/politique-de-confidentialite',
                component: ()=> import("jrmc/vue/prelogin/pages/Privacy.vue"),
                meta: {
                  pageTitle: 'Politique de confidentialité',
                  pageDescription: 'Notre politique de confidentialité'
                },
                name: Routes.PRIVACY
            },
            {
              path: '/politique-de-cookies',
              component: ()=> import("jrmc/vue/prelogin/pages/Cookies.vue"),
              meta: {
                pageTitle: 'Politique de cookies',
                pageDescription: 'Notre politique de cookies'
              },
              name: Routes.COOKIES
            },
            {
                path: '/mentions-legales',
                component: ()=> import("jrmc/vue/prelogin/pages/Legals.vue"),
                meta: {
                  pageTitle: 'Mentions legales',
                  pageDescription: 'Nos mentions legales'
                },
                name: Routes.LEGAL
            }
        ]
    }
]
export default routes
