<script setup lang='ts'>
import { useRoute } from "vue-router";
import { Routes } from "jrmc/vue/prelogin/enums/routes";
import { ref, watch } from "vue";
import RegisterApi from 'jrmc/vue/prelogin/api/register'
import Loader from "jrmc/vue/prelogin/components/Loader.vue"
import { setMetaTags } from "jrmc/vue/prelogin/seo/seo.server";
const route = useRoute()
const error = ref(false);
const isLoading = ref(true);

const metaTag = {
  title: route.meta.pageTitle as string,
  description: route.meta.pageDescription as string,
};
setMetaTags(metaTag);
const activateAccount = async () => {
    const { token } = route.params
    try {
        await RegisterApi.activateAccount(token as string)
    } catch (e) {
        error.value = true
    }
}

await activateAccount();
isLoading.value = false;

</script>
<template>
   <Loader v-if="isLoading"/>
    <div class="page" v-else>
        <div v-if="error" class="container activation-container-error">
            <p>Il semblerait que le token ait déjà été utilisé ou soit invalide.</p>
            <RouterLink :to="{ name: Routes.HOME }" class="btn btn-blue">Retour à l'accueil</RouterLink>
        </div>
        <div v-else class="container activation-container">
            <p>Félicitations !</p>
            <p>Votre compte est maintenant activé </p>
            <RouterLink :to="{ name: Routes.CONNEXION }" class="btn btn-blue">Commencer à utiliser la solution
            </RouterLink>
        </div>
    </div>
</template>
<style scoped>
.activation-container,
.activation-container-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20vh;
}

.activation-container p {
    font-weight: 700;
    font-size: clamp(1.5rem, 2vw, 1.8rem);
}

.activation-container a,
.activation-container-error a {
    margin-top: 1rem;
}
</style>
