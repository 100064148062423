<script setup lang="ts">
import { computed } from "vue";
import useAssets from "jrmc/vue/prelogin/composable/useAssets";

const props = defineProps<{
  title: string;
  img: string;
}>();
const wordWrapped = computed(() => {
  return props.title.split(" ");
});
</script>
<template>
  <article class="quality">
    <div class="image_wrapper">
      <img :src="useAssets(`images/${img}`)" :alt="`pictogramme de ${title}`" width="64" height="64" />
    </div>
    <div class="title">
      <h3 v-for="word in wordWrapped">{{ word }}</h3>
    </div>
  </article>
</template>
<style scoped>
.quality {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  color: var(--color-dark-blue-secondary);
  font-weight: 600;
  text-align: center;
}
.image_wrapper {
  background-color: var(--color-white);
  z-index: 3;
  border: 2px var(--color-orange) solid;
  border-radius: 50%;
}
.quality img {
  object-fit: contain;
  height: 4rem;
  width: 4rem;
  padding: 0.8rem;
}
.quality h3 {
  font-size: 1rem;
  word-wrap: break-word;
}
</style>
